import React, { PureComponent } from "react";
import {
  withStyles,
  Button,
  IconButton,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  Paper,
  FormLabel
} from "@material-ui/core";
import {
  Delete as DeleteIcon,
  Tune as TuneIcon,
  Report as ReportIcon,
  Timeline as TimelineIcon
} from "@material-ui/icons";
import Plotbox from "./Plotbox";
import PropTypes from "prop-types";
import Spinner from "components/Spinner/Spinner";
import Helper from "MetaCell/helper/SimulationResult";
import HelperPlotbox from "MetaCell/helper/Plotbox";
import JsonDialog from "components/JsonDialog/JsonDialog";
import SimulationApi from "MetaCell/api/Simulation";
import "jspdf-autotable";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import DrilldownInput from "components/DrilldownInput";
import { isEqual } from "lodash";
import SimulationSelector from "MetaCell/selectors/Simulation";
import { connect } from "react-redux";
import SimulationAction from "MetaCell/actions/Simulation";
import HelperUtils from "MetaCell/helper/HelperUtils";
import ExportReportMenu from "./ExportReportMenu";
import UnselfishMenu from "components/UnselfishMenu/UnselfishMenu";
import HiddenMaterialGraphs from "./HiddenMaterialGraphs";
import PlotActions from "components/PlotActions/PlotActions";
import DirectionSnackbar from "components/Snackbar/Snackbar";
import { CSVLink } from "react-csv";
import Import from "components/Import";
import ErrorBadge from "components/ErrorBadge/ErrorBadge";

export const styles = {
  left: {
    paddingRight: 260
  },
  right: {
    display: "flex",
    flexDirection: "column",
    width: "200px",
    textAlign: "center",
    padding: "0 30px",
    position: "fixed",
    right: 0,
    top: 150
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "20px",
    zIndex: 100
  },
  buttonMargin: {
    marginBottom: "15px"
  },
  emptyText: {
    textAlign: "center",
    paddingTop: 30
  },
  progress: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    padding: 50
  }
};

export const defaultState = {
  configuration: null,
  jobStatus: null,
  jsonToShow: null,
  resultDetailsMenuAnchorEl: null,
  simulationExportMenuAnchorEl: null,
  params: false,
  showJsonDialog: false,
  showNewSimulationDialog: false,
  plotBoxAdding: false,
  snackbar: {
    visible: false,
    message: ""
  },
  importDialogOpen: false,
  importDialogError: ""
};

/**
 * A component that is rendered when the simulation is complete
 * and display the result
 */
export class SimulationResult extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      configuration: null,
      jobStatus: null,
      jsonToShow: null,
      resultDetailsMenuAnchorEl: null,
      simulationExportMenuAnchorEl: null,
      params: false,
      showJsonDialog: false,
      hide: false,
      plotBoxAdding: false,
      selectedPlotlyPoints: props.selectedPoints || [],
      snackbar: {
        visible: false,
        message: ""
      },
      importDialogOpen: false,
      importDialogError: ""
    };
  }

  componentDidMount() {
    this.getDataToShowSimulationJobResult();
  }

  /**
   * retrieve data related to the job results if a different simulation job is selected
   */
  componentDidUpdate(prevProps) {
    const { simulationJobId } = this.props;
    if (!isEqual(prevProps.simulationJobId, simulationJobId)) {
      this.getDataToShowSimulationJobResult();
    }
  }

  /**
   * it fetches all data regarding the results of the selected job
   */
  getDataToShowSimulationJobResult() {
    this.getParams();
    this.getConfiguration();
    this.getSimulationTaskDetails();
  }

  getSimulationTaskDetails = () => {
    const { simulationJobId } = this.props;
    SimulationApi.getJobDetails(simulationJobId)
      .then(({ data }) => {
        this.setState({
          jobStatus: data
        });
      })
      .catch(() => {});
  };

  getConfiguration = () => {
    const { simulationJobId, handleMissingConfiguration } = this.props;
    SimulationApi.getJobConfiguration(simulationJobId)
      .then(({ data }) => {
        this.setState({
          configuration: data.configuration
        });
        const missingConfiguration =
          data &&
          data.configuration &&
          Object.keys(data.configuration).length === 0;
        if (missingConfiguration) handleMissingConfiguration();
      })
      .catch(() => {});
  };

  getParams = () => {
    const { simulationJobId } = this.props;
    SimulationApi.getJobPlotParams(simulationJobId)
      .then(({ data }) => {
        this.setState({ params: data });
      })
      .catch(() => {});
  };

  /**
   * it opens the json dialog with the simulation configuration data
   */
  showConfigurationJson = () => {
    this.showJsonDialog(this.state.configuration);
  };

  /**
   * it opens the json dialog with errors and warnings of the simulation result
   */
  showResultsErrorsAndWarnings = () => {
    const { errors, warnings } = this.state.jobStatus;
    this.showJsonDialog({ errors, warnings });
  };

  /**
   * it opens the json dialog with metrics of the simulation result
   */
  showResultMetrics = () => {
    const { metrics } = this.state.jobStatus;
    this.showJsonDialog({ metrics: Helper.formatJobMetrics(metrics) });
  };

  /**
   * it opens the dialog with a json to be shown
   * @param {Object} jsonToShow
   */
  showJsonDialog = jsonToShow => {
    this.setState({
      showJsonDialog: true,
      jsonToShow
    });
  };

  hideJsonDialog = () => {
    this.setState({ showJsonDialog: false });
  };

  getSimulationJobsList = jobs => {
    const { simulationJobId } = this.props;
    return jobs.map(job => {
      const label = HelperUtils.getJobLabel(job);
      return {
        id: job.id,
        text: label,
        isSelected: job.id === simulationJobId
      };
    });
  };

  /**
   * it resets the state and selects the job id if it is not already selected
   * @param {String} selectedOptionIndex - the index of the selected option
   * @callback
   */
  onJobSelect = selectedOptionIndex => {
    const { jobs, selectSimulationJob, simulationJobId } = this.props;
    const selectedJobId = parseInt(
      Object.values(jobs.byId)[selectedOptionIndex].id,
      10
    );
    if (simulationJobId !== selectedJobId) {
      this.resetState();
      selectSimulationJob(selectedJobId);
    }
  };

  /**
   * sets the default state
   */
  resetState() {
    this.setState({ ...defaultState });
  }

  /**
   * @param {Number} value - the value to be formatted
   * @returns {String} a 7 decimal places number if the value is a number
   */
  getFormattedValue(value) {
    return value !== undefined && value !== null ? value.toFixed(7) : null;
  }

  getYData = (yData, rangeValue) => {
    var finalYData = yData;
    if (rangeValue?.length > 0 && finalYData) {
      rangeValue.forEach(value => {
        finalYData = finalYData[value - 1];
      });
      return finalYData;
    }
    return finalYData[0];
  };

  getZData = (zData, rangeValue) => {
    var finalZData = zData;
    if (rangeValue?.length > 0 && finalZData) {
      rangeValue.forEach(value => {
        finalZData = finalZData[value - 1];
      });
      return finalZData;
    }
    return finalZData[0];
  };

  addHighlightPoint = point => {
    const { selectPointsAction } = this.props;
    const { selectedPlotlyPoints } = this.state;
    selectPointsAction([...selectedPlotlyPoints, point]);
    this.setState({
      selectedPlotlyPoints: [...selectedPlotlyPoints, point]
    });
  };

  removeHighlightPoints = pointsToRemove => {
    const { selectPointsAction } = this.props;
    const { selectedPlotlyPoints } = this.state;
    const pointsLeft = selectedPlotlyPoints.filter(
      p => !pointsToRemove.includes(p)
    );
    this.setState({
      selectedPlotlyPoints: pointsLeft
    });
    selectPointsAction(pointsLeft);
  };

  handleResultSelection = async (point, event, data) => {
    this.setState({
      snackbar: {
        visible: false,
        message: ""
      }
    });
    const { plotType, scatterOptions } = data,
      { selectResultsAction, selectedPlotResults, plotboxes } = this.props,
      plotId = data.id,
      clickedX = point.points[0].x,
      clickedY = point.points[0].y,
      clickedPointIndex = point.points[0].pointIndex,
      clickedZ =
        plotType === "2D"
          ? null
          : plotType === "SC"
          ? point.points[0]["marker.color"]
          : point.points[0].z;
    let sweepVariableValues = await HelperPlotbox.getSweepVariableValues(
      clickedX,
      clickedY,
      clickedPointIndex,
      data
    );

    const selectedResults = [...selectedPlotResults];
    const plotlyPoint = { ...point.points[0], plotId: plotId };
    let matchedPlotResult = selectedResults.find(plotResult =>
      HelperUtils.deepEqual(plotResult.sweepVariableValues, sweepVariableValues)
    );
    if (matchedPlotResult) {
      this.setState({
        snackbar: {
          visible: true,
          message: "Selected point matches existing selected structure"
        }
      });
      this.addHighlightPoint(plotlyPoint);
      if (Array.isArray(matchedPlotResult.points))
        matchedPlotResult.points.push(plotlyPoint);
      else matchedPlotResult.points = [plotlyPoint];
      selectResultsAction(selectedResults);
      return;
    }
    let formattedSweepVariableValues = HelperPlotbox.formatSweepVariableValues(
      sweepVariableValues
    );
    const sliderStep = data.rangeValue - 1;
    let xValue, yValue, zValue;
    if (sweepVariableValues) {
      Object.keys(sweepVariableValues).forEach(key => {
        if (key === data.xName) {
          xValue = sweepVariableValues[key];
        }
        if (key === data.yName) {
          yValue = sweepVariableValues[key];
        }
        if (key === data.zName) {
          zValue = sweepVariableValues[key];
        }
      });
    }

    let plotValues = [];
    if (plotType === "2D") {
      const xIndex = data.xData.indexOf(xValue);
      yValue = this.getYData(data.yData, data.rangeValue)[xIndex];
      yValue = this.getFormattedValue(yValue);
      plotValues.push({
        yName: data.yName,
        yValue
      });
    }
    if (plotType === "3D") {
      const xIndex = data.xData.indexOf(xValue);
      const yIndex = data.yData.indexOf(yValue);
      zValue = this.getZData(data.zData, data.rangeValue)[xIndex][yIndex];
      zValue = this.getFormattedValue(zValue);
      plotValues.push({
        zName: data.zName,
        zValue
      });
    }
    if (plotType === "SC") {
      if (scatterOptions.x === "output") {
        plotValues.push({
          output_x: `${data.xName}(x)`,
          value: this.getFormattedValue(clickedX)
        });
      }
      if (scatterOptions.y === "output") {
        plotValues.push({
          output_y: `${data.yName}(y)`,
          value: this.getFormattedValue(clickedY)
        });
      }
      if (scatterOptions.color === "output") {
        plotValues.push({
          output_color: `${data.zName}(color)`,
          value: this.getFormattedValue(clickedZ)
        });
      }
    }

    const idList = selectedPlotResults.map(item => item.id);
    const selectedResult = {
      id: !selectedPlotResults.length ? 0 : Math.max.apply(null, idList) + 1,
      x: clickedX,
      y: clickedY,
      z: clickedZ ? clickedZ.toFixed(7) : null,
      points: [plotlyPoint],
      sweepVariableValues,
      formattedSweepVariableValues,
      plotValues,
      plotType,
      scatterOptions
    };

    const newResults = [...selectedPlotResults, selectedResult];
    this.addHighlightPoint(plotlyPoint);
    selectResultsAction(newResults);
  };

  getSelectedSweepValues = sweepVariableValues => {
    const items = [];
    if (sweepVariableValues) {
      Object.keys(sweepVariableValues).forEach(key => {
        items.push(
          <Typography variant="subtitle1" key={key}>
            {key} <br />
            {sweepVariableValues[key]}
          </Typography>
        );
      });
    }
    return items;
  };

  getSelectedPlotValues = (plotValues, plotType, scatterOptions) => {
    const items = [];
    plotValues.forEach((values, key) => {
      Object.keys(values).forEach(key => {
        let value = values[key];
        if (values[key] && values[key].toString().indexOf("/")) {
          const labelsArray = values[key].toString().split("/");
          value = labelsArray[labelsArray.length - 1];
        }

        items.push(
          <Typography variant="subtitle1" noWrap="true">
            {value} <br />
          </Typography>
        );
      });
    });
    return items;
  };

  removeSelectedPlotResult = async resultItemId => {
    const { selectResultsAction, selectedPlotResults } = this.props;
    const results = [...selectedPlotResults];
    const ids = results.map(item => item.id);
    const resultItem = results[ids.indexOf(resultItemId)];
    if (resultItem.points) this.removeHighlightPoints(resultItem.points);
    results.splice(ids.indexOf(resultItemId), 1);
    selectResultsAction(results);
  };

  getSweptVarNames() {
    const { params, configuration } = this.state;
    if (params) {
      var excludeProbeName = null;
      if (
        String(configuration?.probe_parameters?.probe_position)?.startsWith("=")
      ) {
        const probeName = configuration?.probe_parameters?.probe_position;
        const configString = JSON.stringify({
          ...configuration,
          probe_parameters: null
        });
        if (!configString.includes(`"${probeName}"`))
          excludeProbeName = probeName.substring(1);
      }
      const sweptVarNames = Object.keys(params[1]).filter(
        svName =>
          !configuration?.swept_variables?.find(
            sv => sv.name == svName && sv.sweepType == "Formula"
          ) && svName != excludeProbeName
      );
      return sweptVarNames.map(key => key).sort((a, b) => (a > b ? 1 : -1));
    }
    return [];
  }

  getOptimizationVarNames() {
    const { params, configuration } = this.state;
    if (params) {
      var excludeProbeName = null;
      if (
        String(configuration?.probe_parameters?.probe_position)?.startsWith("=")
      ) {
        const probeName = configuration?.probe_parameters?.probe_position;
        const configString = JSON.stringify({
          ...configuration,
          probe_parameters: null
        });
        if (!configString.includes(`"${probeName}"`))
          excludeProbeName = probeName.substring(1);
      }
      const sweptVarNames = Object.keys(params[1]).filter(
        svName =>
          configuration?.swept_variables?.find(
            sv => sv.name == svName && sv.sweepType == "Optimization"
          ) && svName != excludeProbeName
      );
      return sweptVarNames.map(key => key).sort((a, b) => (a > b ? 1 : -1));
    }
    return [];
  }

  getDefaultPlotParams = () => {
    const sweptVars = this.getSweptVarNames();
    const optimizationVars = this.getOptimizationVarNames();
    let plotType = "";
    let xName = "";
    let yName = "";
    let zName = null;
    let sliderName = "";
    let scatterOptions = {};
    if (optimizationVars.length > 0) {
      // adjust defaults for backward compatibility
      let defaultPhase = "T/0,0/TE/field_coeff/Phase";
      let defaultAbs = "T/0,0/TE/field_coeff/Abs";
      if (this.state.params && this.state.params[0].length > 5) {
        defaultPhase = "/T/0,0/TE/field_coeff/Phase";
        defaultAbs = "/T/0,0/TE/field_coeff/Abs";
      }
      plotType = "SC";
      xName = sweptVars.length > 0 ? sweptVars[0] : "";
      yName = sweptVars.length > 1 ? sweptVars[1] : defaultPhase;
      zName = sweptVars.length > 1 ? defaultPhase : defaultAbs;
      scatterOptions = {
        x: "sweep",
        y: sweptVars.length > 1 ? "sweep" : "output",
        color: "output",
        plot_best: true
      };
    } else {
      plotType = sweptVars.length > 1 ? "3D" : "2D";
      xName = sweptVars.length > 0 ? sweptVars[0] : "";
      yName = sweptVars.length > 1 ? sweptVars[1] : "";
      sliderName = sweptVars.length > 2 ? sweptVars[2] : "";
    }
    return {
      plotType,
      xName,
      yName,
      zName,
      sliderName,
      scatterOptions
    };
  };

  addPlotbox = async () => {
    this.setState({ plotBoxAdding: true });
    const addPlot = jobId => {
      return SimulationApi.addJobPlot(jobId, this.getDefaultPlotParams());
    };
    await this.props.addPlotbox(addPlot);
    this.props.forcePlotboxManagerRemount();
    this.setState({ plotBoxAdding: false });
  };

  getAllPlotsCSV = () => {
    const { plotboxes } = this.props;
    return Helper.getAllPlotsCSVData(plotboxes);
  };

  handleCsvResultSelection = async csvPoints => {
    const { selectResultsAction, selectedPlotResults } = this.props;
    const { swept_variables } = this.state.configuration;
    const configuration = this.state.configuration;
    const selectedResults = [...selectedPlotResults];
    const idList = selectedPlotResults.map(item => item.id);
    let matchedPlotResults = [];
    const csvSelectedResults = [];
    const originalCSVData = this.getAllPlotsCSV();
    const comparisonTolerance = 1e-6;
    var excludeProbeName = null;
    if (
      String(configuration?.probe_parameters?.probe_position)?.startsWith("=")
    ) {
      const probeName = configuration?.probe_parameters?.probe_position;
      const configString = JSON.stringify({
        ...configuration,
        probe_parameters: null
      });
      if (!configString.includes(`"${probeName}"`))
        excludeProbeName = probeName.substring(1);
    }
    const sweepVarNames = swept_variables
      .filter(sv => sv.sweepType != "Formula" && sv.name != excludeProbeName)
      .map(sv => sv.name);
    let checkSubset = (parentArray, subsetArray) => {
      return subsetArray.every(el => {
        return parentArray.includes(el);
      });
    };

    return new Promise((resolve, reject) => {
      try {
        csvPoints.forEach((point, index) => {
          delete point["selected"];
          const sweepKeys = Object.keys(point).filter(
            key => !key.includes("/")
          );
          if (!checkSubset(sweepKeys, sweepVarNames)) {
            throw new Error(
              `Some of the sweep variable names are missing in CSV headers. Expected Columns - ${sweepVarNames.toString()}...selected (0/1)`
            );
          }
          const outputKeys = Object.keys(point).filter(key =>
            key.includes("/")
          );
          let sweepVariableValues = sweepVarNames.reduce((result, key) => {
            // sometimes users use software that automatically rounds values with many decimals
            // then we cannot find a match in the db. We want to send correct data to backend
            // to fetch results without issues. So filter the original csv data and try to find
            // the matching original value, unrounded, and replace the user-imported value with that
            let originalValueMatch = false;
            if (Array.isArray(originalCSVData)) {
              originalValueMatch = originalCSVData.reduce(
                (minDiffRow, currentRow) => {
                  const currentDifference = Math.abs(
                    currentRow[key] - point[key]
                  );
                  if (currentDifference < minDiffRow.minDifference) {
                    return {
                      row: currentRow,
                      minDifference: currentDifference
                    };
                  }
                  return minDiffRow;
                },
                { row: null, minDifference: Infinity }
              );
            }
            if (
              originalValueMatch &&
              originalValueMatch.minDifference < comparisonTolerance
            ) {
              result[key] = Number(originalValueMatch.row[key]);
            } else {
              result[key] = Number(point[key]);
            }
            return result;
          }, {});
          let matchedPlotResult = selectedResults.find(plotResult =>
            HelperUtils.deepEqual(
              plotResult.sweepVariableValues,
              sweepVariableValues
            )
          );
          if (matchedPlotResult) {
            matchedPlotResults.push(point);
            return;
          }
          let formattedSweepVariableValues = HelperPlotbox.formatSweepVariableValues(
            sweepVariableValues
          );
          let plotValues = outputKeys.map(key => {
            return {
              output: key,
              value: this.getFormattedValue(Number(point[key]))
            };
          });
          let selectedResult = {
            id: !selectedPlotResults.length
              ? 0
              : Math.max.apply(null, idList) + (1 + index),
            sweepVariableValues,
            formattedSweepVariableValues,
            plotValues
          };
          csvSelectedResults.push(selectedResult);
        });
        const newResults = [...selectedPlotResults, ...csvSelectedResults];
        selectResultsAction(newResults);
        resolve();
      } catch (exception) {
        reject(exception.message);
      }
    });
  };

  handleCsvFile = acceptedFiles => {
    if (acceptedFiles.length > 0) {
      try {
        const file = acceptedFiles[0];
        let csvSelectedResults = [];
        Helper.readCsvSelectionFile(file)
          .then(data => {
            csvSelectedResults = data.filter(
              d => d.selected == "1" || d.selected == "TRUE"
            );
            this.handleCsvResultSelection(csvSelectedResults)
              .then(() => {
                this.setState({
                  importDialogOpen: false,
                  importDialogError: ""
                });
              })
              .catch(err => {
                this.setState({ importDialogError: err });
              });
          })
          .catch(err => {
            this.setState({ importDialogError: err });
          });
      } catch (err) {
        this.setState({ importDialogError: err });
      }
    }
  };

  render = () => {
    const {
        classes,
        plotboxes,
        showConfirmPlotboxDeletion,
        jobs,
        simulationJobId,
        status,
        inWizardView,
        selectedPlotResults,
        showNewSimulation,
        disablePlotBoxUpdate
      } = this.props,
      {
        resultDetailsMenuAnchorEl,
        simulationExportMenuAnchorEl,
        params,
        configuration,
        jsonToShow,
        showJsonDialog,
        hide,
        plotBoxAdding,
        selectedPlotlyPoints,
        jobStatus
      } = this.state,
      thereIsConfiguration =
        configuration && Object.keys(configuration).length > 0,
      plotParamsWereLoaded = params !== false,
      thereArePlotParams = plotParamsWereLoaded && params !== "";
    const disabledActions = this.props.disabledActions || [];

    return hide ? null : (
      <div test-data="simulationResult">
        {(!plotboxes || !plotParamsWereLoaded) && (
          <div className={classes.progress}>
            <Spinner name="Waiting" size={68} timeout={30000} />
          </div>
        )}
        {plotboxes && plotParamsWereLoaded && thereIsConfiguration && (
          <div test-data="simulationResultContent">
            <div
              className={classes.right}
              test-data="resultActions"
              style={
                inWizardView
                  ? {
                      position: "sticky",
                      float: "right",
                      top: 0,
                      width: "180px"
                    }
                  : {}
              }
            >
              {jobs && (
                <div style={{ marginBottom: 20, textAlign: "left" }}>
                  <FormLabel>Simulation Job</FormLabel>
                  <DrilldownInput
                    name="DesignJob"
                    marginTop={10}
                    onClose={(value, data) => {}}
                    options={[
                      this.getSimulationJobsList(Object.values(jobs.byId))
                    ]}
                    showCount={false}
                    value={HelperUtils.getJobLabel(
                      Object.values(jobs.byId).filter(
                        job => job.id === simulationJobId
                      )[0]
                    )}
                    onSelect={this.onJobSelect}
                  />
                </div>
              )}
              <Button
                name="AddPlotButton"
                className={classes.buttonMargin}
                variant="contained"
                color="primary"
                onClick={this.addPlotbox}
                test-data="add-plot"
                disabled={
                  plotBoxAdding || !thereArePlotParams || status === "FAILED"
                }
              >
                Add plot
              </Button>
              <ExportReportMenu
                exportPlotReport={Helper.buildAndDownloadPlotsReport}
                exportConfigurationReport={
                  Helper.buildAndDownloadConfigurationReport
                }
                exportFullReport={Helper.buildAndDownloadFullReport}
                simulationName={this.props.simulationName}
                projectName={this.props.projectName}
                username={this.props.username}
                configuration={this.state.configuration}
                simulationJobId={this.props.simulationJobId}
                btnClassName={classes.buttonMargin}
                disabled={
                  !thereArePlotParams ||
                  disabledActions.indexOf("export") !== -1 ||
                  status === "FAILED"
                }
              />
              <Tooltip title={"Run new simulation with current configuration"}>
                <div>
                  <Button
                    test-data={"newSimulationBtn"}
                    name="NewSimulationButton"
                    className={classes.buttonMargin}
                    variant="contained"
                    onClick={e => showNewSimulation(plotboxes)}
                    disabled={disabledActions.indexOf("export") !== -1}
                  >
                    New simulation
                  </Button>
                </div>
              </Tooltip>
              <Button
                test-data={"ResultDetailsButton"}
                name="ResultDetailsButton"
                className={classes.buttonMargin}
                variant="contained"
                onClick={e =>
                  this.setState({
                    resultDetailsMenuAnchorEl: e.currentTarget
                  })
                }
              >
                <ErrorBadge
                  errors={jobStatus?.errors}
                  warnings={jobStatus?.warnings}
                  buttonLabel="Result Details"
                />
              </Button>
              {inWizardView && (
                <CSVLink
                  separator={";"}
                  data={this.getAllPlotsCSV()}
                  style={{ color: "inherit" }}
                  filename={`all_${this.props.simulationName}.csv`}
                  enclosingCharacter={""}
                >
                  <Button
                    name="exportCsvButton"
                    className={classes.buttonMargin}
                    variant="contained"
                  >
                    Export Results (CSV)
                  </Button>
                </CSVLink>
              )}
              {inWizardView && (
                <Button
                  name="importCsvButton"
                  className={classes.buttonMargin}
                  variant="contained"
                  onClick={() => {
                    this.setState({
                      importDialogOpen: true
                    });
                  }}
                >
                  Import Selection (CSV)
                  <Import
                    open={this.state.importDialogOpen}
                    loading={false}
                    onClose={() => {
                      this.setState({
                        importDialogOpen: false,
                        importDialogError: ""
                      });
                    }}
                    onDrop={this.handleCsvFile}
                    message="Drag and drop a csv file with your selections here, or click here to select a file"
                    error={this.state.importDialogError}
                    accept=".csv, text/csv"
                  />
                </Button>
              )}

              <UnselfishMenu
                onClick={e =>
                  this.setState({ resultDetailsMenuAnchorEl: null })
                }
                elevation={0}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center"
                }}
                anchorEl={resultDetailsMenuAnchorEl}
                open={Boolean(resultDetailsMenuAnchorEl)}
                PaperProps={{
                  style: {
                    border: "1px solid #d3d4d5"
                  }
                }}
              >
                <MenuItem onClick={this.showConfigurationJson}>
                  <ListItemIcon>
                    <TuneIcon />
                  </ListItemIcon>
                  <ListItemText primary="Configuration" />
                </MenuItem>
                <MenuItem onClick={this.showResultsErrorsAndWarnings}>
                  <ListItemIcon>
                    <ReportIcon
                      style={{
                        color: `${
                          Boolean(
                            jobStatus?.errors &&
                              Object.keys(jobStatus?.errors).length
                          )
                            ? "red"
                            : Boolean(
                                jobStatus?.warnings &&
                                  Object.keys(jobStatus?.warnings).length
                              )
                            ? "orange"
                            : "default"
                        }`
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Errors and Warnings" />
                </MenuItem>
                <MenuItem onClick={this.showResultMetrics}>
                  <ListItemIcon>
                    <TimelineIcon />
                  </ListItemIcon>
                  <ListItemText primary="Metrics" />
                </MenuItem>
              </UnselfishMenu>
              {inWizardView && (
                <div
                  className="selectedResultsWrapper"
                  style={{
                    position: "sticky",
                    float: "left",
                    top: 0,
                    width: "180px"
                  }}
                >
                  {!selectedPlotResults.length && (
                    <Paper
                      style={{
                        minHeight: "50px",
                        padding: "12px",
                        marginBottom: "20px"
                      }}
                    >
                      <Typography variant="subtitle1">
                        No Selected Result
                      </Typography>
                    </Paper>
                  )}

                  {selectedPlotResults &&
                    selectedPlotResults.map((item, i) => {
                      const {
                        sweepVariableValues,
                        plotValues,
                        plotType,
                        scatterOptions
                      } = item;
                      return (
                        <Paper
                          test-data="plotResultsList"
                          key={i}
                          style={{
                            minHeight: "50px",
                            padding: "12px",
                            marginBottom: "20px"
                          }}
                        >
                          <div style={{ float: "right" }}>
                            <IconButton
                              test-data="deleteResultButton"
                              edge="end"
                              aria-label="delete"
                              onClick={this.removeSelectedPlotResult.bind(
                                this,
                                item.id
                              )}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>

                          {this.getSelectedSweepValues(sweepVariableValues)}

                          {plotValues &&
                            this.getSelectedPlotValues(
                              plotValues,
                              plotType,
                              scatterOptions
                            )}
                        </Paper>
                      );
                    })}
                </div>
              )}
            </div>

            <div>
              <div className={`${classes.left}`}>
                {status !== "STOPPED" &&
                  status !== "FAILED" &&
                  status !== "ERROR" &&
                  plotboxes.length === 0 && (
                    <div test-data="message" className={classes.emptyText}>
                      Simulation completed. Once you add a plot it will be shown
                      here.
                    </div>
                  )}
                {status === "ERROR" && (
                  <div test-data="message" className={classes.emptyText}>
                    Simulation completed with errors. Consult 'result
                    details/errors and warnings' to learn more. Once you add a
                    plot it will be shown here.
                  </div>
                )}
                {status === "FAILED" && (
                  <div test-data="message" className={classes.emptyText}>
                    Consult 'result details/errors and warnings' to learn more.
                  </div>
                )}
                {status === "STOPPED" && (
                  <div className={classes.emptyText}>Simulation STOPPED.</div>
                )}
                {status !== "FAILED" &&
                  status !== "STOPPED" &&
                  plotboxes.map((data, index) => (
                    <div key={data.id}>
                      <Paper
                        style={{
                          padding: 5,
                          paddingBottom: 10,
                          marginBottom: 5,
                          overflow: "hidden"
                        }}
                      >
                        <PlotActions
                          plotboxes={plotboxes}
                          id={data.id}
                          simulationName={this.props.simulationName}
                          projectName={this.props.projectName}
                          username={this.props.username}
                          getCSV={Helper.getCSVData}
                          deletePlotbox={this.props.deletePlotbox}
                          updatePlotRange={this.props.updatePlotRange}
                        />
                        <Plotbox
                          configuration={configuration}
                          params={params}
                          sweptVarNames={this.getSweptVarNames()}
                          optVarNames={this.getOptimizationVarNames()}
                          data={data}
                          plotIndex={index}
                          onPlotboxChange={newData => {
                            this.props.onPlotBoxChange(data.id, newData);
                          }}
                          onRangeValueChange={(newValue, index) => {
                            this.props.onRangeValueChange(
                              data.id,
                              newValue,
                              index
                            );
                          }}
                          onGetUnwrappedOutputData={wrapped =>
                            this.props.onGetUnwrappedOutputData(
                              data.id,
                              wrapped
                            )
                          }
                          showErrorsAndWarnings={() =>
                            this.showJsonDialog(data.postProcesLog)
                          }
                          handleResultSelection={async (point, event) => {
                            if (inWizardView) {
                              await this.handleResultSelection(
                                point,
                                event,
                                data
                              );
                            }
                          }}
                          errorTitle={"Plot corrupted"}
                          errorDescription={"Your plot has been corrupted."}
                          disableSideView={inWizardView}
                          disablePlotBoxUpdate={disablePlotBoxUpdate}
                          errorsAndWarnings={data.postProcesLog}
                        />
                      </Paper>
                    </div>
                  ))}
              </div>
            </div>
            <HiddenMaterialGraphs configuration={this.state.configuration} />
          </div>
        )}

        <JsonDialog
          open={showJsonDialog}
          data={jsonToShow}
          onClose={this.hideJsonDialog}
        />
        {this.state.snackbar.visible && (
          <DirectionSnackbar message={this.state.snackbar.message} />
        )}
      </div>
    );
  };
}

const mapStateToProps = state => ({
  selectedPlotResults: SimulationSelector.getSelectedResults(state),
  selectedPoints: SimulationSelector.getSelectedPoints(state)
});

const mapDispatchToProps = dispatch => ({
  selectResultsAction: data => dispatch(SimulationAction.selectResults(data)),
  selectPointsAction: data => dispatch(SimulationAction.selectPoints(data))
});

SimulationResult.propTypes = {
  simulationId: PropTypes.number.isRequired,
  simulationJobId: PropTypes.number.isRequired,
  runNewSimulation: PropTypes.func.isRequired,
  simulations: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorBoundary(withStyles(styles)(SimulationResult)));
