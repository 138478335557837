import Axios from "axios";
import DesignAction from "MetaComponent/actions/Design";
import GenericApi from "Api";
import AnalysisAction from "MetaComponent/actions/Analysis";
import OdaAction from "MetaComponent/actions/ODA";

/**
 * A class created to prepare data and perform http operations
 * related to a meta component design.
 */
export default class DesignApi {
  /**
   * it generates an url to start the design phase for a meta component
   * @param {Number} metaComponentId - the meta component id
   */
  static getDesignUrl = metaComponentId =>
    `${GenericApi.getBaseUrl()}/metacomponents/${metaComponentId}/startdesign`;

  /**
   * it generates a design job url
   * @param {Number} jobId - the design job id
   */
  static getDesignJobUrl = jobId =>
    `${GenericApi.getBaseUrl()}/designjobs/${jobId}`;

  /**
   * @param {Number} designJobId - the id of the design job
   * @returns {String} the url to restore an analysis
   */
  static getRestoreAnalysisUrl = designJobId =>
    `${this.getDesignJobUrl(designJobId)}/restore_analysis`;

  static getRestoreOdaUrl = designJobId =>
    `${this.getDesignJobUrl(designJobId)}/restore_oda`;

  /**
   * it generates an url to get all the design jobs
   */
  static getDesignJobsUrl = () => `${GenericApi.getBaseUrl()}/designjobs`;

  /**
   * it generates an url to get all the design jobs filtered by meta component
   */
  static getDesignJobsByMetaComponentUrl = metaComponentId =>
    `${this.getDesignJobsUrl()}/?meta_component=${metaComponentId}`;

  /**
   * it generates an url to get the progress of a design job
   */
  static getDesignJobProgressUrl = designJobId =>
    `${GenericApi.getBaseUrl()}/designjobs/${designJobId}/progress`;

  /**
   * it generates an url to get the targets of a design job
   */
  static getDesignJobTargetsUrl = designJobId =>
    `${GenericApi.getBaseUrl()}/designjobs/${designJobId}/targets`;

  /**
   * it generates an url to get the report of a design job
   */
  static getDesignJobReportUrl = designJobId =>
    `${GenericApi.getBaseUrl()}/designjobs/${designJobId}/report`;

  /**
   * it generates an url to get the mask of a design job
   */
  static getDesignJobMaskUrl = designJobId =>
    `${GenericApi.getBaseUrl()}/designjobs/${designJobId}/get_mask`;

  /**
   * @returns {String} url to start a mask generation
   */
  static startDesignJobMaskUrl = designJobId =>
    `${GenericApi.getBaseUrl()}/designjobs/${designJobId}/start_mask`;

  static stopDesignJobMaskUrl = designJobId =>
    `${GenericApi.getBaseUrl()}/designjobs/${designJobId}/stop_mask`;

  /**
   * @returns {String} url to get status of a mask generation
   */
  static getDesignJobMaskStatusUrl = designJobId =>
    `${GenericApi.getBaseUrl()}/designjobs/${designJobId}/mask_progress`;

  /**
   * it generates an url to get the report of a design job
   */
  static getDesignJobReportUrl = designJobId =>
    `${GenericApi.getBaseUrl()}/designjobs/${designJobId}/get_report`;

  /**
   * @returns {String} url to start a report generation
   */
  static startDesignJobReportUrl = designJobId =>
    `${GenericApi.getBaseUrl()}/designjobs/${designJobId}/start_report`;

  static stopDesignJobReportUrl = designJobId =>
    `${GenericApi.getBaseUrl()}/designjobs/${designJobId}/stop_report`;

  /**
   * @returns {String} url to get status of a report generation
   */
  static getDesignJobReportStatusUrl = designJobId =>
    `${GenericApi.getBaseUrl()}/designjobs/${designJobId}/report_progress`;

  static getDesignJobMemberMapUrl = designJobId =>
    `${GenericApi.getBaseUrl()}/designjobs/${designJobId}/get_mapjob`;

  static startDesignJobMemberMapUrl = designJobId =>
    `${GenericApi.getBaseUrl()}/designjobs/${designJobId}/start_mapjob`;

  static stopDesignJobMemberMapUrl = designJobId =>
    `${GenericApi.getBaseUrl()}/designjobs/${designJobId}/stop_mapjob`;

  static getDesignJobMemberMapStatusUrl = designJobId =>
    `${GenericApi.getBaseUrl()}/designjobs/${designJobId}/mapjob_progress`;

  /**
   * it makes a request to start a design
   * @param {Number} metaComponentId - the meta component id
   * @returns {Function} a function that receives and uses the redux dispatcher
   */
  static run = (metaComponentId, errorHandler) => {
    return dispatch => {
      const url = DesignApi.getDesignUrl(metaComponentId);
      return GenericApi.runApiCall(
        Axios.post(url),
        "Failed to start design job"
      )
        .then(response => response.data)
        .then(designTask => dispatch(DesignAction.upsertJobs([designTask])))
        .catch(error => {
          if (typeof errorHandler === "function") {
            errorHandler(error.response);
          }
          console.log("Simulation API: failed to start design");
        });
    };
  };

  /**
   * it makes a request to the design jobs endpoint and retrieve the ones
   * that are linked to the meta component
   * @returns {Function} a function that receives and uses the redux dispatcher
   */
  static getDesignJobs = metaComponentId => {
    return dispatch => {
      const url = DesignApi.getDesignJobsByMetaComponentUrl(metaComponentId);
      return Axios.get(url)
        .then(response => response.data)
        .then(designTasks => {
          dispatch(DesignAction.set(designTasks));
        });
    };
  };

  /**
   * it makes a request to the job progress backend endpoint
   * @param {Number} designJobId - the design job id
   * @returns {Promise} the response
   */
  static getDesignJobProgress = designJobId => {
    const url = DesignApi.getDesignJobProgressUrl(designJobId);
    return GenericApi.runApiCall(Axios.get(url), "Design does not progress.");
  };

  static getDesignJobMaskProgress = designJobId => {
    const url = this.getDesignJobMaskStatusUrl(designJobId);
    return Axios.get(url);
  };

  static getDesignJobReportProgress = designJobId => {
    const url = this.getDesignJobReportStatusUrl(designJobId);
    return Axios.get(url);
  };

  /**
   * it stops a running design job
   * @param {Number} jobId - the job id
   */
  static stopDesignJob = jobId => {
    const url = `${this.getDesignJobUrl(jobId)}/stop`;
    return Axios.post(url);
  };

  /**
   * it stops a running mask job
   * @param {Number} designJobId - the design job id
   */
  static stopMaskJob = designJobId => {
    const url = this.stopDesignJobMaskUrl(designJobId);
    return Axios.post(url);
  };

  /**
   * it makes a request to export a design job report
   * @param {Number} jobId - the job id
   * @returns {Promise} the response
   */
  static exportDesignReport = jobId => {
    const url = this.getDesignJobReportUrl(jobId);
    return GenericApi.runApiCall(
      Axios({
        url,
        method: "GET",
        responseType: "blob"
      }),
      "Failed to export design report."
    );
  };

  /**
   * it makes a request to get a design job mask
   * @param {Number} jobId - the job id
   * @returns {Promise} the response
   */
  static getDesignMask = jobId => {
    const url = this.getDesignJobMaskUrl(jobId);
    return GenericApi.runApiCall(
      Axios({
        url,
        method: "GET",
        responseType: "blob"
      }),
      "Failed to export mask file."
    );
  };

  /**
   * it makes a request to start a design job mask generation
   * @param {Number} jobId - the job id
   * @returns {Promise} the response
   */
  static startDesignMask = (jobId, nrPoints) => {
    const url = this.startDesignJobMaskUrl(jobId);
    return GenericApi.runApiCall(
      Axios.post(url, { nr_points: nrPoints }),
      "Failed to export mask file."
    );
  };

  /**
   * it makes a request to get a design job mask
   * @param {Number} jobId - the job id
   * @returns {Promise} the response
   */
  static getDesignMaskStatus = jobId => {
    const url = this.getDesignJobMaskStatusUrl(jobId);
    return GenericApi.runApiCall(Axios.get(url), "Failed to export mask file.");
  };

  /**
   * it stops a running report job
   * @param {Number} reportJobId - the report job id
   */
  static stopReportJob = reportJobId => {
    const url = this.stopDesignJobReportUrl(reportJobId);
    return Axios.post(url);
  };

  static stopMemberMapJob = reportJobId => {
    const url = this.stopDesignJobMemberMapUrl(reportJobId);
    return Axios.post(url);
  };

  /**
   * it makes a request to get a design job report
   * @param {Number} jobId - the job id
   * @returns {Promise} the response
   */
  static getDesignReport = jobId => {
    const url = this.getDesignJobReportUrl(jobId);
    return GenericApi.runApiCall(
      Axios({
        url,
        method: "GET",
        responseType: "blob"
      }),
      "Failed to export report file."
    );
  };

  static getDesignMemberMapJson = jobId => {
    const url = this.getDesignJobMemberMapUrl(jobId);
    return GenericApi.runApiCall(
      Axios({
        url,
        method: "GET",
        responseType: "blob"
      }),
      "Failed to export member map file."
    );
  };

  /**
   * it makes a request to start a design job report generation
   * @param {Number} jobId - the job id
   * @returns {Promise} the response
   */
  static startDesignReport = jobId => {
    const url = this.startDesignJobReportUrl(jobId);
    return GenericApi.runApiCall(
      Axios.post(url),
      "Failed to export report file."
    );
  };

  static startMemberMapJob = jobId => {
    const url = this.startDesignJobMemberMapUrl(jobId);
    return GenericApi.runApiCall(
      Axios.post(url),
      "Failed to export member map file."
    );
  };

  /**
   * it makes a request to get a design job report
   * @param {Number} jobId - the job id
   * @returns {Promise} the response
   */
  static getDesignReportStatus = jobId => {
    const url = this.getDesignJobReportStatusUrl(jobId);
    return GenericApi.runApiCall(
      Axios.get(url),
      "Failed to export report file."
    );
  };

  static getDesignMemberMapStatus = jobId => {
    const url = this.getDesignJobMemberMapStatusUrl(jobId);
    return GenericApi.runApiCall(
      Axios.get(url),
      "Failed to export member map file."
    );
  };

  /**
   * it makes a request to obtain the targets used by a design job
   * @param {Number} jobId
   */
  static getDesignJobTargets = jobId =>
    Axios.get(this.getDesignJobTargetsUrl(jobId)).then(res => res.data);

  /**
   * @param {Number} jobId - the id of the design job
   * @returns {Function} a function that receives the redux dispatcher
   */
  static deleteDesignJob = jobId => dispatch => {
    const url = this.getDesignJobUrl(jobId);
    return Axios.delete(url).then(() =>
      dispatch(DesignAction.deleteJobs([jobId]))
    );
  };

  /**
   * @param {Number[]} jobIds - the id of the design jobs
   * @returns {Function} a function that receives the redux dispatcher
   */
  static deleteDesignJobs = jobIds => dispatch => {
    const url = this.getDesignJobUrl("");
    return Axios.delete(url, { data: jobIds }).then(() =>
      dispatch(DesignAction.deleteJobs(jobIds))
    );
  };

  /**
   * @param {Number} jobIds - the id of the design jobs
   * @returns {Function} a function that receives the redux dispatcher
   */
  static deleteDesignJob = jobIds => dispatch => {
    const url = this.getDesignJobUrl(jobIds);
    return Axios.delete(url).then(() =>
      dispatch(DesignAction.deleteJobs([jobIds]))
    );
  };

  static getDesignJob = jobId => dispatch => {
    const url = this.getDesignJobUrl(jobId);
    return Axios.get(url)
      .then(res => res.data)
      .then(job => dispatch(DesignAction.upsertJobs([job])));
  };

  /**
   * @param {Number} designJobId - the design job into which the analysis will be restored
   * @param {Number} analysisJobId - the analysis job from which the analysis will be restored
   * @returns {Function} a function that receives the redux dispatcher
   */
  static restoreAnalysis = (designJobId, analysisJobId) => {
    return dispatch => {
      return Axios.post(this.getRestoreAnalysisUrl(designJobId), {
        analysis_job_id: analysisJobId
      })
        .then(res => res.data)
        .then(analysisJob =>
          dispatch(AnalysisAction.upsertJobs([analysisJob]))
        );
    };
  };

  static restoreOda = (designJobId, odaJobId) => {
    return dispatch => {
      return Axios.post(this.getRestoreOdaUrl(designJobId), {
        oda_job_id: odaJobId
      })
        .then(res => res.data)
        .then(odaJob => dispatch(OdaAction.upsertJobs([odaJob])));
    };
  };
}
