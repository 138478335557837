import Axios from "axios";
import IncidentLightAction from "MetaCell/actions/IncidentLight";
import GenericApi from "Api";

/**
 * A class created to perform http operations related to the incident light entity.
 * @author Akira Kotsugai
 * TODO: extract the similarities it has with GlobalParametersApi and IncidentLightApi
 */
export default class IncidentLightApi {
  /**
   * it generates a url to fetch incident light by a given simulation
   */
  static getBySimulationUrl = simulationId =>
    `${GenericApi.getBaseUrl()}/incidentlights/?simulation=${simulationId}`;

  /**
   * it generates the url to the incident light api to manipulate
   * the given incident light.
   *@param id - the incident light id
   */
  static getUrl = (id = "") =>
    `${GenericApi.getBaseUrl()}/incidentlights/${id}`;

  /**
   * it can fetch the simulation's incident light. If it is a brand new simulation
   * it will create the record in the database.
   * @param {Number} simulationId - the open simulation's id
   * @return a function that receive a dispatcher to redux
   */
  static fetch = simulationId => {
    return dispatch => {
      const bySimulationUrl = IncidentLightApi.getBySimulationUrl(simulationId);
      return Axios.get(bySimulationUrl)
        .then(res => res.data)
        .then(incidentLight => {
          if (incidentLight.length > 0)
            dispatch(IncidentLightAction.set(incidentLight));
          else {
            const url = IncidentLightApi.getUrl();
            const incidentLight = {
              azimut: "0",
              zenit: "0",
              wavelength: "550",
              polarization: "TE",
              amplitude: "1",
              simulation: simulationId
            };
            return Axios.post(url, incidentLight)
              .then(res => res.data)
              .then(newIncidentLight =>
                dispatch(IncidentLightAction.set([newIncidentLight]))
              );
          }
        })
        .catch(error => {
          console.log("error from API:", error.message);
        });
    };
  };

  static createIncidentLight = IncidentLight => dispatch =>
    Axios.post(IncidentLightApi.getUrl(), IncidentLight)
      .then(res => res.data)
      .then(newIncidentLight => {
        const targetAction = IncidentLightAction.upsert([newIncidentLight]);
        dispatch(targetAction);
        return newIncidentLight.id;
      });

  /**
   * it can save the existing incident light. we don't always want to update the state
   * with the saved entity like when we are opening another simulation after the save,
   * that's why we dont dispatch any action.
   * @param {Object} IncidentLight - the incident light object
   * @return {Function} a function that receives a dispatcher to redux
   */
  static put = incidentLight => {
    return dispatch => {
      const url = IncidentLightApi.getUrl(incidentLight.id);
      return Axios.put(url, incidentLight)
        .then(({ data }) => {
          dispatch(IncidentLightAction.update(data));
        })
        .catch(error => {
          console.log("error from API:", error.message);
          return Promise.reject(error);
        });
    };
  };

  static deleteIncidentLight = IncidentLightId => {
    return dispatch => {
      return Axios.delete(IncidentLightApi.getUrl(IncidentLightId)).then(() =>
        dispatch(IncidentLightAction.delete(IncidentLightId))
      );
    };
  };

  static patch = (id, obj) => {
    return dispatch => {
      const url = IncidentLightApi.getUrl(id);
      return Axios.patch(url, obj)
        .then(({ data }) => {
          dispatch(IncidentLightAction.changeEditingField(data));
          dispatch(IncidentLightAction.update(data));
        })
        .catch(error => {
          console.log("error from API:", error.message);
          return Promise.reject(error);
        });
    };
  };
}
