import Axios from "axios";
import OutputSettingsAction from "MetaCell/actions/OutputSettings";
import DirectoryExplorerAction from "MetaCell/actions/DirectoryExplorer";
import GenericApi from "Api";

export default class OutputSettingsApi {
  static outputSettingsUrl = (id = "") =>
    `${GenericApi.getBaseUrl()}/output_settings/${id}`;

  static outputSettingsBySimulationIdUrl = id =>
    `${GenericApi.getBaseUrl()}/output_settings/?simulation=${id}`;

  static fetchOutputSettings = (id = "") => {
    return dispatch => {
      return this.requestOutputSettings(id).then(outputSettings => {
        dispatch(OutputSettingsAction.set(outputSettings));
        return outputSettings;
      });
    };
  };

  static fetchOutputSettingsBySimulation = simulationId => {
    return dispatch => {
      return this.requestOutputSettingsBySimulationId(simulationId).then(
        outputSettings => {
          dispatch(OutputSettingsAction.set(outputSettings));
          return outputSettings;
        }
      );
    };
  };

  static requestOutputSettingsBySimulationId = id => {
    const url = OutputSettingsApi.outputSettingsBySimulationIdUrl(id);
    return Axios.get(url)
      .then(res => {
        const outputSettings = id ? res.data : res.data;
        return outputSettings;
      })
      .catch(error => {
        console.log("Output settings error from API: ", error.message);
      });
  };

  static requestOutputSettings = id => {
    const url = OutputSettingsApi.outputSettingsUrl(id);
    return Axios.get(url)
      .then(res => {
        const outputSettings = id ? res.data : res.data;
        return outputSettings;
      })
      .catch(error => {
        console.log("Output settings error from API: ", error.message);
      });
  };

  static updateOutputSetting = outputSetting => {
    return dispatch => {
      return Axios.put(
        OutputSettingsApi.outputSettingsUrl(outputSetting.id),
        outputSetting
      )
        .then(() => dispatch(OutputSettingsAction.upsert([outputSetting])))
        .catch(error => {
          console.log("Output setting update error from API: ", error.message);
          return Promise.reject(error);
        });
    };
  };

  static createOutputSetting = outputSetting => dispatch =>
    Axios.post(OutputSettingsApi.outputSettingsUrl(), outputSetting)
      .then(res => res.data)
      .then(newOutputSetting => {
        const targetAction = OutputSettingsAction.upsert([newOutputSetting]);
        dispatch(targetAction);
        return newOutputSetting.id;
      });

  static deleteOutputSetting = outputSettingId => {
    return dispatch => {
      return Axios.delete(
        OutputSettingsApi.outputSettingsUrl(outputSettingId)
      ).then(() => dispatch(OutputSettingsAction.delete(outputSettingId)));
    };
  };
}
