import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core";
import EnhancedMaterialTable from "components/EnhancedMaterialTable/EnhancedMaterialTable";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import SweepInput from "components/SweepInput/SweepInput";
import Polarization from "components/Polarization/Polarization";
import DirectionSnackbar from "components/Snackbar/Snackbar";

const styles = {
  main: {
    width: "100%",
    boxSizing: "border-box",
    paddingRight: "20px"
  },
  inputTooltip: {
    whiteSpace: "pre-line"
  }
};

const getComponent = (
  props,
  columnName,
  fieldName,
  selectedIncidentLightId,
  missingFields,
  defaultValue = ""
) => {
  return (
    <div>
      <SweepInput
        name={columnName}
        disabled={false}
        value={
          props.value !== undefined && props.value !== null
            ? props.value
            : defaultValue
        }
        onChange={props.onChange}
        allowEmpty={true}
      />
      {(selectedIncidentLightId === props.rowData.id || !props.rowData.id) &&
        missingFields.includes(fieldName) &&
        !props.value && (
          <div style={{ color: "red", fontSize: "12px" }}>
            This field is required
          </div>
        )}
    </div>
  );
};

const getColumns = (selectedIncidentLightId, missingFields) => [
  {
    title: "Name",
    field: "name"
  },
  {
    title: "Azimuth",
    field: "azimut",
    editComponent: props =>
      getComponent(
        props,
        "IncidentLightAzimut",
        "azimut",
        selectedIncidentLightId,
        missingFields,
        "0"
      )
  },
  {
    title: "Zenith",
    field: "zenit",
    editComponent: props =>
      getComponent(
        props,
        "IncidentLightZenit",
        "zenit",
        selectedIncidentLightId,
        missingFields,
        "0"
      )
  },
  {
    title: "Amplitude",
    field: "amplitude",
    editComponent: props =>
      getComponent(
        props,
        "IncidentLightAmplitude",
        "amplitude",
        selectedIncidentLightId,
        missingFields,
        "1"
      )
  },
  {
    title: "Wavelength",
    field: "wavelength",
    editComponent: props =>
      getComponent(
        props,
        "IncidentLightWavelength",
        "wavelength",
        selectedIncidentLightId,
        missingFields
      )
  },
  {
    title: "Polarization",
    field: "polarization",
    editComponent: props => {
      return (
        <div>
          <Polarization
            updateFieldCallback={({ polarization }) =>
              props.onChange(polarization)
            }
            polarization={props.value}
            gridColumnXs={12}
            isSweep
            hideLabel
          />
          {(selectedIncidentLightId === props.rowData.id ||
            !props.rowData.id) &&
            missingFields.includes("polarization") &&
            !props.value && (
              <div style={{ color: "red", fontSize: "12px" }}>
                This field is required
              </div>
            )}
        </div>
      );
    }
  }
];

export class IncidentLightsTable extends PureComponent {
  constructor() {
    super();
    this.state = {
      selectedIncidentLightId: null,
      missingFields: [],
      error: null
    };
  }

  selectIncidentLight = id => {
    const { focusOnIncidentLight } = this.props;
    this.setState({
      selectedIncidentLightId: id
    });
    focusOnIncidentLight(id);
  };

  getSelectedEntityId() {
    const { selectedIncidentLightId } = this.state;
    return selectedIncidentLightId;
  }

  getMergedData = newData => {
    const defaultValues = {
      azimut: "0",
      zenit: "0",
      amplitude: "1"
    };
    const requiredFields = ["wavelength", "polarization"];
    const newRowData = {
      azimut: newData.azimut || defaultValues.azimut,
      zenit: newData.zenit || defaultValues.zenit,
      amplitude: newData.amplitude || defaultValues.amplitude
    };
    newData = { ...newData, ...newRowData };

    let missingFields = [];
    for (let field of requiredFields) {
      if (newData[field] === undefined || newData[field] === "") {
        missingFields.push(field);
      }
    }
    return [newData, missingFields];
  };

  onIncidentLightAdd = newData => {
    const {
      createIncidentLight,
      fetchIncidentLightsAction,
      fetchOutputSettingsAction
    } = this.props;
    const [newDataMerged, missingFields] = this.getMergedData(newData);

    return new Promise((resolve, reject) => {
      if (missingFields.length === 0) {
        this.setState({ missingFields: missingFields });
        createIncidentLight(newDataMerged)
          .then(() => {
            fetchIncidentLightsAction(newDataMerged.simulation).then(() =>
              fetchOutputSettingsAction(newDataMerged.simulation).then(() =>
                resolve()
              )
            );
          })
          .catch(e => {
            this.setState({
              error: e.response?.data ? e.response?.data[0] : null
            });
            reject();
          });
      } else {
        this.setState({ missingFields: missingFields });
        reject();
      }
    });
  };

  onIncidentLightUpdate = newData => {
    const {
      saveIncidentLight,
      fetchIncidentLightsAction,
      fetchOutputSettingsAction
    } = this.props;
    const [newDataMerged, missingFields] = this.getMergedData(newData);

    return new Promise((resolve, reject) => {
      if (missingFields.length === 0) {
        this.setState({ missingFields: missingFields });
        saveIncidentLight(newDataMerged)
          .then(() => {
            fetchIncidentLightsAction(newDataMerged.simulation).then(() =>
              fetchOutputSettingsAction(newDataMerged.simulation).then(() =>
                resolve()
              )
            );
          })
          .catch(e => {
            this.setState({
              error: e.response?.data ? e.response?.data[0] : null
            });
            reject();
          });
      } else {
        this.setState({ missingFields: missingFields });
        reject();
      }
    });
  };

  onIncidentLightDelete = oldData => {
    return new Promise((resolve, reject) => {
      const { deleteIncidentLight, fetchIncidentLightsAction } = this.props;
      deleteIncidentLight(oldData.id)
        .then(() => {
          fetchIncidentLightsAction(oldData.simulation).then(() => resolve());
        })
        .catch(() => {
          reject();
        });
    });
  };

  handleRowEditCancel = oldData => {
    // Clear missingFields when cancel is clicked
    this.setState({ missingFields: [] });
    this.setState({ selectedIncidentLightId: null });
  };

  render = () => {
    const { incidentLights } = this.props;

    return (
      <div>
        <EnhancedMaterialTable
          options={{
            search: false,
            paging: false,
            sorting: false,
            draggable: false,
            maxBodyHeight: 350,
            actionsColumnIndex: -1
          }}
          title="Incident Light Parameters"
          columns={getColumns(
            this.state.selectedIncidentLightId,
            this.state.missingFields
          )}
          data={incidentLights}
          selectEntity={this.selectIncidentLight}
          selectedEntityId={this.getSelectedEntityId()}
          editable={{
            onRowAdd: this.onIncidentLightAdd,
            onRowUpdate: this.onIncidentLightUpdate,
            onRowDelete:
              incidentLights.length > 1 ? this.onIncidentLightDelete : null
          }}
          onEditingCancelled={oldData => {
            this.handleRowEditCancel(oldData);
          }}
        />
        {this.state.error !== null && (
          <DirectionSnackbar message={this.state.error} />
        )}
      </div>
    );
  };
}
export default withErrorBoundary(withStyles(styles)(IncidentLightsTable));
