import Axios from "axios";
import FFWTTargetAction from "MetaComponent/actions/FFWTTarget";
import DesignTargetAction from "MetaComponent/actions/DesignTarget";
import DirectoryExplorerAction from "MetaComponent/actions/DirectoryExplorer";
import GenericApi from "Api";
import { decode } from "@msgpack/msgpack";
import Script2DAction from "MetaComponent/actions/Script2D";
import FFWFTargetHelper from "MetaComponent/helper/FFWFTarget";
import HelperUtils from "MetaCell/helper/HelperUtils";
import debounce from "lodash.debounce";

export default class DesignTargetApi {
  /**
   * it generates the url to the design target api
   * @param {Number} id - the design target id if there is one
   * @return {String} - the generated design target endpoint url
   */
  static designTargetsUrl = (id = "") =>
    `${GenericApi.getBaseUrl()}/designtargets/${id}`;

  /**
   * it generates the url to the far-field wavefront target api
   * @param {Number} id - the far-field wavefront target id if there is one
   * @return {String} - the generated far-field wavefront target endpoint url
   */
  static ffwfTargetsUrl = (id = "") =>
    `${GenericApi.getBaseUrl()}/ffwftargets/${id}`;

  /**
   * it generates the url to import the wavefront of a ffwf target
   * @param {Number} id - the ffwf target id
   * @return {String} - the generated url
   */
  static ffwfTargetsImportWavefrontUrl = id =>
    `${this.ffwfTargetsUrl(id)}/importwf`;

  /**
   * it generates the url to export the wavefront of a ffwf target
   * @param {Number} id - the ffwf target id
   * @return {String} - the generated url
   */
  static ffwfTargetsExportWavefrontUrl = id =>
    `${this.ffwfTargetsUrl(id)}/exportwf`;

  /**
   * it generates the url to export the ffwf target
   * @param {Number} id - the ffwf target id
   * @return {String} - the generated url
   */
  static ffwfTargetsExportUrl = id => `${this.ffwfTargetsUrl(id)}/export`;

  /**
   * it generates the url to export the nf wavefront of a design target
   * @param {Number} id - the design target id
   * @return {String} - the generated url
   */
  static nfwfTargetsExportWavefrontUrl = id =>
    `${this.designTargetsUrl(id)}/exportnfwf`;

  /**
   * it generates the url to import the nf wavefront of a design target
   * @param {Number} id - the design target id
   * @return {String} - the generated url
   */
  static nfwfTargetsImportWavefrontUrl = id =>
    `${this.designTargetsUrl(id)}/importnfwf`;

  /**
   * it generates the url to delete the nf wavefront of a design target
   * @param {Number} id - the design target id
   * @return {String} - the generated url
   */
  static nfwfTargetsDeleteWavefrontUrl = id =>
    `${this.designTargetsUrl(id)}/deletenfwf`;

  /**
   * it generates the url to obtain the wavefront of a ffwf target
   * @param {Number} id - the ffwf target id
   * @return {String} - the generated url
   */
  static ffwfTargetWavefrontUrl = id => `${this.ffwfTargetsUrl(id)}/wavefront`;

  /**
   * it generates the url to obtain the nf wavefront of a design target
   * @param {Number} id - the ffwf target id
   * @return {String} - the generated url
   */
  static nfwfTargetWavefrontUrl = id =>
    `${this.designTargetsUrl(id)}/wavefront`;

  /**
   * @param {Number} id - the design target id
   * @return {String} - url to get wavefront images
   */
  static nfwfTargetImagesWavefrontUrl = id =>
    `${this.nfwfTargetWavefrontUrl(id)}_imgs`;

  /**
   * @param {Number} id - the design target id
   * @return {String} - url to get wavefront shape
   */
  static nfwfTargetWavefrontShapeUrl = id =>
    `${this.nfwfTargetWavefrontUrl(id)}_shape`;

  /**
   * @returns {String} url to get status of a nfwf import job
   */
  static getDesignTargetNFWFImportStatusUrl = id =>
    `${this.designTargetsUrl(id)}/nfwfimport_progress`;

  /**
   * @returns {String} url to stop a nfwf import job
   */
  static getDesignTargetNFWFImportStopUrl = id =>
    `${this.designTargetsUrl(id)}/nfwfimport_stop`;

  static getS3WavefrontFileDataUrl = () =>
    `${this.designTargetsUrl()}get_wavefront_data_from_s3`;

  /**
   * it generates the url to duplicate a design target
   * @param {Number} id - the design target id
   * @return {String} - the generated url
   */
  static designTargetDuplicateUrl = id =>
    `${this.designTargetsUrl(id)}/duplicate`;

  static designTargetUsedMCsUrl = id => `${this.designTargetsUrl(id)}/used_mcs`;

  static AsyncNfwfImportUrl = id =>
    `${this.designTargetsUrl(id)}/async_nfwf_import/`;

  static asyncFfwfImportUrl = id =>
    `${this.designTargetsUrl(id)}/async_ffwf_import/`;

  static getDesignTargetAsyncNFWFImportStatusUrl = id =>
    `${this.designTargetsUrl(id)}/async_nfwf_import/progress`;

  static getDesignTargetAsyncFFWFImportStatusUrl = id =>
    `${this.designTargetsUrl(id)}/async_ffwf_import/progress`;

  /**
   * it can fetch all the existing design targets in the api's database
   * @return {Function} a function that receive a dispatcher to redux
   */
  static fetchDesignTargets = (id = "") => {
    return dispatch => {
      return this.requestDesignTargets(id).then(designTargets => {
        dispatch(DesignTargetAction.set(designTargets));
        return designTargets;
      });
    };
  };

  static requestDesignTargets = id => {
    const url = DesignTargetApi.designTargetsUrl(id);
    return Axios.get(url)
      .then(res => {
        const designTargets = id ? [res.data] : res.data;
        return designTargets;
      })
      .catch(error => {
        console.log("Design targets error from API: ", error.message);
      });
  };

  /**
   * it makes a request to change field values of an existing design target
   * @param {*} id - the design target
   * @param {Object} newProps - the new fields values
   * @returns {Function} a function that receive a dispatcher to redux
   */
  static updateDesignTarget = (id, newProps) => dispatch =>
    Axios.patch(DesignTargetApi.designTargetsUrl(id), newProps)
      .then(res => res.data)
      .then(updatedDT => dispatch(DesignTargetAction.upsert([updatedDT])));

  /**
   * it can fetch all the existing far-field wavefront targets in the api's database
   * @return {Function} a function that receive a dispatcher to redux
   */
  static fetchFFWFTargets = (id = "") => {
    return dispatch => {
      const url = DesignTargetApi.ffwfTargetsUrl(id);
      return Axios.get(url)
        .then(res => {
          const ffwfTargets = res.data;
          dispatch(FFWTTargetAction.set(ffwfTargets));
          return ffwfTargets;
        })
        .catch(error => {
          console.log("FFWF targets error from API: ", error.message);
        });
    };
  };

  /**
   * it makes a request to import a wavefront file
   * @param {Number} ffwfTargetId - the ffwf target to have the wavefront changed
   * @param {File} wavefrontFile - the wavefront file
   * @returns {Promise} the request promise
   */
  static updateFFWFTargetWavefront = async (
    ffwfTargetId,
    wavefrontFile,
    dimensions,
    fileError
  ) => {
    const formData = new FormData();
    formData.append("file", wavefrontFile);
    const readFile = await FFWFTargetHelper.readWavefrontFile(wavefrontFile);
    const designScript = readFile["design_script"];
    if (designScript !== undefined) {
      const data = {
        code: designScript,
        dimensions: dimensions
      };
      const pollImportStatus = async ffwfTargetId => {
        while (true) {
          const response = await this.getAsyncFFWFImportStatus(ffwfTargetId);
          const data = response.data;
          if (data.status === "ERROR") {
            return Promise.reject(data.errors);
          } else if (data.status === "DONE") {
            return data;
          } else if (data.status === "FAILED") {
            return Promise.reject(new Error("Import failed"));
          }
          await new Promise(resolve => setTimeout(resolve, 5000));
        }
      };

      try {
        await this.startAsyncFfwfJob(ffwfTargetId, data);
        // Wait for the job to complete,
        // because we only want to exit the loading window when the image is fully rendered
        await pollImportStatus(ffwfTargetId);
        return await GenericApi.runApiCall(
          Axios.post(
            this.ffwfTargetsImportWavefrontUrl(ffwfTargetId),
            formData
          ).then(res => res.data),
          "Failed to import wavefront"
        );
      } catch (error) {
        console.error(error.response?.data || "An error occurred");
        return Promise.reject(error);
      }
    } else {
      return GenericApi.runApiCall(
        Axios.post(
          this.ffwfTargetsImportWavefrontUrl(ffwfTargetId),
          formData
        ).then(res => res.data),
        "Failed to import wavefront"
      );
    }
  };

  /**
   * it makes a request to import the nfwf target wavefront file in chunks of 1000000 points
   * @param {*} designTargetId - which design target
   * @param {*} wavefrontFile - the file
   * @param {*} chunk_points_limit - the max number of points a wavefront chunk must have
   * @returns {Promise} - the response promise
   */
  static importNFWFTargetWavefront = async (
    designTargetId,
    file,
    s3FileKey,
    chunkPointsLimit,
    dimensions
  ) => {
    if (s3FileKey) {
      await this.submitNFWavefront(
        designTargetId,
        undefined, //wavefront file
        s3FileKey,
        1,
        1
      );
      return {
        id: designTargetId
      };
    } else {
      let xLength;
      let yLength;
      let wavefrontObject;
      const readFile = await FFWFTargetHelper.readWavefrontFile(file);
      let readableWavefront = readFile["NFWaveFront"];
      if (typeof readableWavefront === "string") {
        readableWavefront = JSON.parse(atob(readableWavefront));
      }
      if (readableWavefront !== undefined) {
        const shape = HelperUtils.getMatrixShape(readableWavefront[0]);
        [xLength, yLength] = shape;
      } else {
        xLength = readFile["NFWidth"];
        yLength = readFile["NFHeight"];
      }
      const designScript = readFile["design_script"];
      const chunkRowsCount = parseInt(chunkPointsLimit / xLength);
      const nrOfChunks = Math.ceil(yLength / chunkRowsCount);

      if (readableWavefront !== undefined) {
        for (let i = 0; i < nrOfChunks; i++) {
          const begginingOfSlice = i * chunkRowsCount;
          if (begginingOfSlice >= yLength) {
            throw Error(
              "its only possible to slice within the wavefront height range"
            );
          }
          const endOfSlice = begginingOfSlice + chunkRowsCount;
          const wavefrontSlice = [
            readableWavefront[0].slice(begginingOfSlice, endOfSlice),
            readableWavefront[1].slice(begginingOfSlice, endOfSlice)
          ];
          wavefrontObject = {
            NFWaveFront: wavefrontSlice,
            NFWidth: readableWavefront["NFWidth"],
            NFHeight: readableWavefront["NFHeight"],
            nfwf_phase_unit: "radians",
            nfwf_amplitude_unit: "V/m"
          };
          const wavefrontJson = JSON.stringify(wavefrontObject);
          const blob = new Blob([wavefrontJson], { type: "application/json" });
          const wavefrontFile = new File([blob], "file.json");

          await this.submitNFWavefront(
            designTargetId,
            wavefrontFile,
            undefined, //s3 file key
            nrOfChunks,
            i + 1,
            designScript
          );
        }
        delete readableWavefront.NFWaveFront;
        return {
          id: designTargetId,
          ...readableWavefront
        };
      } else {
        await this.submitNFWavefront(
          designTargetId,
          undefined,
          undefined,
          1,
          1,
          designScript
        );
        const data = {
          code: designScript,
          dimensions: dimensions
        };
        const getAsyncNFWFImportStatusWithDelay = debounce(
          this.getAsyncNFWFImportStatus,
          5000
        );
        await this.startAsyncNfwfJob(designTargetId, data)
          .then(() => {
            this.getAsyncNFWFImportStatus(designTargetId)
              .then(resp => resp.data)
              .then(data => {
                if (data.status === "ERROR") {
                  getAsyncNFWFImportStatusWithDelay.cancel();
                  return Promise.reject(data.errors);
                }
                if (data.status === "QUEUED" || data.status === "RUNNING") {
                  return getAsyncNFWFImportStatusWithDelay(designTargetId);
                } else if (data.status === "DONE" || data.status === "FAILED") {
                  if (data.status === "DONE") {
                    getAsyncNFWFImportStatusWithDelay.cancel();
                  }
                }
              });
          })
          .catch(e => {
            console.log(e.response?.data || "Invalid code");
          });
        return {
          id: designTargetId
        };
      }
    }
  };

  /**
   * @returns {Promise} - request promise to submit wavefront
   */
  static submitNFWavefront(
    designTargetId,
    wavefrontFile,
    s3FileKey,
    nr_of_chunks,
    chunk_index,
    designScript
  ) {
    const formData = new FormData();
    if (s3FileKey) formData.append("s3FileKey", s3FileKey);
    else formData.append("file", wavefrontFile);
    formData.append("chunks_count", nr_of_chunks);
    formData.append("chunk_index", chunk_index);
    if (designScript !== undefined) {
      formData.append("design_script", designScript);
    }
    return Axios.post(
      this.nfwfTargetsImportWavefrontUrl(designTargetId),
      formData
    );
  }

  /**
   * @param {Number} designTargetId - the design target that will have its wavefront deleted
   * @returns {Function} a function that receive a dispatcher to redux
   */
  static deleteNFWFTargetWavefront = designTargetId => {
    return dispatch => {
      return Axios.post(this.nfwfTargetsDeleteWavefrontUrl(designTargetId))
        .then(res => res.data)
        .then(designTarget =>
          dispatch(DesignTargetAction.upsert([designTarget]))
        );
    };
  };

  /**
   * it makes a request to update an existing far-field wavefront targets in the api's database
   * @param {Number} ffwfTargetId - the ffwf target to have the properties changed
   * @param {Object} properties - the properties to change
   * @returns {Promise} the request promise
   */
  static updateFFWFTargetBasicProperties = (ffwfTargetId, properties) => {
    const url = DesignTargetApi.ffwfTargetsUrl(ffwfTargetId);
    return Axios.patch(url, properties).then(res => res.data);
  };

  /**
   * it makes a request to create a far-field wavefront target in the api's database
   * @param {Object} ffwfTarget - the object contaning the properties
   * @returns {Function} a function that receive a dispatcher to redux
   */
  static createFFWFTarget = ffwfTarget => {
    return dispatch => {
      // detaching the wavefront file from the other properties because they use different endpoints
      const { wavefrontFile } = ffwfTarget;
      delete ffwfTarget.wavefrontFile;
      return Axios.post(DesignTargetApi.ffwfTargetsUrl(), ffwfTarget)
        .then(res => res.data)
        .then(newFFWFTarget => {
          const targetAction = FFWTTargetAction.upsert([newFFWFTarget]);
          const scriptAction = Script2DAction.upsert([
            newFFWFTarget.wave_front_design_script
          ]);
          if (wavefrontFile) {
            return this.updateFFWFTargetWavefront(
              newFFWFTarget.id,
              wavefrontFile
            ).then(() =>
              this.dispatchTargetAndScript(dispatch, targetAction, scriptAction)
            );
          } else {
            return this.dispatchTargetAndScript(
              dispatch,
              targetAction,
              scriptAction
            );
          }
        });
    };
  };

  static dispatchTargetAndScript(dispatcher, targetAction, scriptAction) {
    dispatcher(scriptAction);
    return dispatcher(targetAction);
  }

  /**
   * it checks if wavefronts are being imported or not, calls the correct endpoints
   * and dispatches the update action to the redux store
   * @param {Number} ffwfTargetId
   * @param {Object} newProperties
   * @returns {Function} a function that receive a dispatcher to redux
   */
  static updateFFWFTarget = (ffwfTargetId, newProperties, dimensions) => {
    return dispatch => {
      const allChangedPropertiesNames = Object.keys(newProperties),
        wavefrontChanged =
          allChangedPropertiesNames.filter(name => name === "wavefrontFile")
            .length === 1,
        otherPropertiesChanged =
          allChangedPropertiesNames.filter(name => name !== "wavefrontFile")
            .length > 0;

      // detaching the wavefront from the properties
      const { wavefrontFile } = newProperties;
      delete newProperties.wave_front;
      delete newProperties.wavefrontFile;

      if (wavefrontChanged && otherPropertiesChanged) {
        return this.updateFFWFTargetWavefront(
          ffwfTargetId,
          wavefrontFile,
          dimensions
        ).then(() => {
          return this.updateFFWFTargetBasicProperties(
            ffwfTargetId,
            newProperties
          ).then(ffwfTarget =>
            dispatch(
              FFWTTargetAction.upsert([{ ...ffwfTarget, wave_front: null }])
            )
          );
        });
      } else if (wavefrontChanged) {
        return this.updateFFWFTargetWavefront(
          ffwfTargetId,
          wavefrontFile,
          dimensions
        ).then(ffwfTarget =>
          dispatch(
            FFWTTargetAction.upsert([{ ...ffwfTarget, wave_front: null }])
          )
        );
      } else if (otherPropertiesChanged) {
        return this.updateFFWFTargetBasicProperties(
          ffwfTargetId,
          newProperties
        ).then(ffwfTarget => dispatch(FFWTTargetAction.upsert([ffwfTarget])));
      } else {
        return Promise.resolve(true);
      }
    };
  };

  /**
   * it makes a request to get the binary wavefront of a ffwf target
   * @param {*} ffwfTargetId
   * @returns {Promise} the promise from the axios call
   */
  static getFFWFTargetWavefrontCall = ffwfTargetId => {
    const url = this.ffwfTargetWavefrontUrl(ffwfTargetId);
    const config = {
      responseType: "blob"
    };
    return Axios.get(url, config)
      .then(res => new Response(res.data).arrayBuffer())
      .then(arrayBuffer => decode(arrayBuffer))
      .then(wavefront => {
        return wavefront;
      });
  };

  /**
   * it makes a request to get the binary wavefront of a ffwf target, converts it
   * and dispatches to redux
   * @param {*} ffwfTargetId
   * @param {*} newProperties
   * @returns {Function} a function that receive a dispatcher to redux
   */
  static getFFWFTargetWavefront = ffwfTargetId => dispatch =>
    this.requestFFWFTargetWavefront(ffwfTargetId).then(wavefront =>
      dispatch(FFWTTargetAction.setFFWFTargetWavefront(ffwfTargetId, wavefront))
    );

  static requestFFWFTargetWavefront = ffwfTargetId =>
    Axios({
      url: this.ffwfTargetWavefrontUrl(ffwfTargetId),
      method: "GET",
      responseType: "blob"
    })
      .then(res => new Response(res.data).arrayBuffer())
      .then(arrayBuffer => decode(arrayBuffer));

  /**
   * it makes a request to get the binary wavefront of a design target, converts it
   * and dispatches to redux
   * @param {*} designTargetId
   * @param {*} newProperties
   * @returns {Function} a function that receive a dispatcher to redux
   */
  static getNFWFTargetWavefront = designTargetId => dispatch =>
    this.requestNFWFTargetWavefront(designTargetId).then(wavefront =>
      dispatch(DesignTargetAction.setNFWavefront(designTargetId, wavefront))
    );

  static requestNFWFTargetWavefront = designTargetId =>
    Axios({
      url: this.nfwfTargetWavefrontUrl(designTargetId),
      method: "GET",
      responseType: "blob"
    })
      .then(res => new Response(res.data).arrayBuffer())
      .then(arrayBuffer => decode(arrayBuffer));

  static requestNFWFTargetImagesWavefront = designTargetId =>
    Axios.get(this.nfwfTargetImagesWavefrontUrl(designTargetId)).then(
      res => res.data
    );

  static requestNFWFTargetWavefrontShape = designTargetId =>
    Axios.get(this.nfwfTargetWavefrontShapeUrl(designTargetId))
      .then(res => res.data)
      .then(data => data.shape);

  /**
   * it makes a request to export the wavefront json of a ffwf target
   * @param {Number} ffwfTargetId - which ffwf target
   * @returns {Promise} the request promise
   */
  static exportFFWFTargetWavefront = ffwfTargetId =>
    GenericApi.runApiCall(
      Axios({
        url: this.ffwfTargetsExportWavefrontUrl(ffwfTargetId),
        method: "GET",
        responseType: "blob"
      }).then(res => res.data),
      "Failed to export Far-field wavefront file."
    );

  static exportFFWFTarget = ffwfTargetId =>
    GenericApi.runApiCall(
      Axios({
        url: this.ffwfTargetsExportUrl(ffwfTargetId),
        method: "GET",
        responseType: "blob"
      }).then(res => res.data),
      "Failed to export Far-field file."
    );

  /**
   * it makes a request to export the nf wavefront json of a design target
   * @param {Number} designTargetId - which design target
   * @returns {Promise} the request promise
   */
  static exportNFWFTargetWavefront = designTargetId =>
    GenericApi.runApiCall(
      Axios({
        url: this.nfwfTargetsExportWavefrontUrl(designTargetId),
        method: "GET",
        responseType: "blob"
      }).then(res => res.data),
      "Failed to export Near-field wavefront file."
    );

  /**
   * it makes a request to create a design target in the api's database
   * @param {Object} designTarget - the object contaning the properties
   * @returns {Function} a function that receive a dispatcher to redux
   */
  static createDesignTarget = designTarget => dispatch =>
    Axios.post(DesignTargetApi.designTargetsUrl(), designTarget)
      .then(res => res.data)
      .then(newDesignTarget => {
        const targetAction = DesignTargetAction.upsert([newDesignTarget]);
        const scriptAction = Script2DAction.upsert([
          newDesignTarget.wave_front_design_script
        ]);
        this.dispatchTargetAndScript(dispatch, targetAction, scriptAction);
        return newDesignTarget.id;
      });

  /**
   * it checks imports the file if there is one else just updates the dimensions.
   * @param {*} designTargetId - which design target
   * @param {*} wavefrontProperties - the wavefront properties to change
   * @returns {Function} a function that receives a dispatcher to redux
   */
  static updateNFWFWavefront = (
    designTargetId,
    wavefrontProperties,
    dimensions
  ) => dispatch => {
    const { wavefrontFile, s3FileKey } = wavefrontProperties;
    if (wavefrontFile || s3FileKey) {
      return GenericApi.runApiCall(
        this.importNFWFTargetWavefront(
          designTargetId,
          wavefrontFile,
          s3FileKey,
          500000,
          dimensions
        ).then(designTarget =>
          dispatch(DesignTargetAction.upsert([designTarget]))
        ),
        "Failed to import wavefront"
      );
    }
    return Axios.patch(
      this.designTargetsUrl(designTargetId),
      wavefrontProperties
    )
      .then(res => res.data)
      .then(designTarget =>
        dispatch(DesignTargetAction.upsert([designTarget]))
      );
  };

  /**
   * it makes a request to delete a design target from the database
   * @param {Number} designTargetId - the design target id
   * @returns {Function} a function that receives the redux dispatcher
   */
  static deleteDesignTarget = designTargetId => {
    return dispatch => {
      return Axios.delete(DesignTargetApi.designTargetsUrl(designTargetId))
        .then(() => dispatch(DesignTargetAction.delete(designTargetId)))
        .then(() =>
          dispatch(
            DirectoryExplorerAction.cleanDesignTargetRelations(designTargetId)
          )
        );
    };
  };

  /**
   * it makes a request to delete a ffwftarget from the database
   * @param {Number} ffwfTargetId - the ffwftarget id
   * @returns {Function} a function that receives the redux dispatcher
   */
  static deleteFFWFTarget = ffwfTargetId => {
    return dispatch => {
      return Axios.delete(
        DesignTargetApi.ffwfTargetsUrl(ffwfTargetId)
      ).then(() => dispatch(FFWTTargetAction.delete(ffwfTargetId)));
    };
  };

  /**
   * it makes a request to get the status of the nfwf import
   * @param {Number} designTargetId - the id
   * @returns {Promise} the response
   */
  static getNFWFImportStatus = (designTargetId, ignoreError = false) => {
    const url = this.getDesignTargetNFWFImportStatusUrl(designTargetId);
    if (ignoreError) {
      return Axios.get(url);
    }
    return GenericApi.runApiCall(Axios.get(url), "Failed to import wavefront.");
  };

  static stopNFWFImport = designTargetId => {
    const url = this.getDesignTargetNFWFImportStopUrl(designTargetId);
    return Axios.get(url);
  };

  /**
   * @param {Number} designTargetId - the design target that needs to be duplicated
   * @returns {Function} a function that receive a dispatcher to redux
   */
  static duplicateDesignTarget = designTargetId => {
    return dispatch => {
      return Axios.post(this.designTargetDuplicateUrl(designTargetId))
        .then(res => res.data)
        .then(newduplicateDesignTarget => {
          const targetAction = DesignTargetAction.upsert([
            newduplicateDesignTarget
          ]);
          const scriptAction = Script2DAction.upsert([
            newduplicateDesignTarget.wave_front_design_script
          ]);
          this.dispatchTargetAndScript(dispatch, targetAction, scriptAction);
          return newduplicateDesignTarget.id;
        });
    };
  };

  static getDesignTargetUsedMCs = designTargetId => {
    return Axios.get(this.designTargetUsedMCsUrl(designTargetId)).then(
      res => res.data
    );
  };

  static startAsyncNfwfJob = (id, data) => {
    const url = this.AsyncNfwfImportUrl(id) + "start/";
    return Axios.post(url, data).then(res => res);
  };

  static startAsyncFfwfJob = (id, data) => {
    const url = this.asyncFfwfImportUrl(id) + "start/";
    return Axios.post(url, data).then(res => res);
  };

  static getAsyncNFWFImportStatus = (designTargetId, ignoreError = false) => {
    const url = this.getDesignTargetAsyncNFWFImportStatusUrl(designTargetId);
    if (ignoreError) {
      return Axios.get(url);
    }
    return GenericApi.runApiCall(
      Axios.get(url),
      "Failed to generate wavefront."
    );
  };

  static getAsyncFFWFImportStatus = (designTargetId, ignoreError = false) => {
    const url = this.getDesignTargetAsyncFFWFImportStatusUrl(designTargetId);
    if (ignoreError) {
      return Axios.get(url);
    }
    return GenericApi.runApiCall(
      Axios.get(url),
      "Failed to generate wavefront."
    );
  };

  static stopNFWFImport = designTargetId => {
    const url = this.getDesignTargetNFWFImportStopUrl(designTargetId);
    return Axios.get(url);
  };

  static getS3WavefrontFileData = s3FileKey => {
    const url = this.getS3WavefrontFileDataUrl() + `?s3FileKey=${s3FileKey}`;
    return GenericApi.runApiCall(
      Axios.get(url),
      "Failed to get wavefront file information from file upload server."
    );
  };
}
