import React from "react";
import { Badge } from "@material-ui/core";
import "./index.css";

export default function ErrorBadge({ errors, warnings, buttonLabel }) {
  return (
    <Badge
      badgeContent={"!"}
      className={`customBadge ${
        Boolean(errors && Object.keys(errors).length)
          ? "badgeError"
          : "badgeWarning"
      }`}
      invisible={
        (!errors || !Object.keys(errors).length) &&
        (!warnings || !Object.keys(warnings).length)
      }
    >
      {buttonLabel}
    </Badge>
  );
}
