import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core";
import EnhancedMaterialTable from "components/EnhancedMaterialTable/EnhancedMaterialTable";
import Polarization from "components/Polarization/Polarization";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import DrilldownInput from "components/DrilldownInput";
import SweepInput from "components/SweepInput/SweepInput";
import DirectionSnackbar from "components/Snackbar/Snackbar";

const styles = {
  main: {
    width: "100%",
    boxSizing: "border-box",
    paddingRight: "20px"
  },
  inputTooltip: {
    whiteSpace: "pre-line"
  }
};

export const getColumns = () => [
  {
    title: "Direction",
    field: "direction",
    editComponent: props => {
      return (
        <DrilldownInput
          marginTop={0}
          value={props.value}
          options={[
            [
              {
                text: "R",
                isSelected: props.value === "R",
                next: false
              },
              {
                text: "T",
                isSelected: props.value === "T",
                next: false
              },
              {
                text: "A",
                isSelected: props.value === "A",
                next: false
              }
            ]
          ]}
          onClose={props.onChange}
        />
      );
    }
  },
  {
    title: "Diffraction Order X",
    field: "diffraction_order_x",
    editComponent: props => {
      return (
        <SweepInput
          name="OutputSettingsDiffractionOrderX"
          disabled={false}
          value={props.value ? props.value : ""}
          onChange={props.onChange}
        />
      );
    }
  },
  {
    title: "Diffraction Order Y",
    field: "diffraction_order_y",
    editComponent: props => {
      return (
        <SweepInput
          name="OutputSettingsDiffractionOrderY"
          disabled={false}
          value={props.value ? props.value : ""}
          onChange={props.onChange}
        />
      );
    }
  },
  {
    title: "Polarization",
    field: "polarization_in",
    editComponent: props => {
      return (
        <Polarization
          updateFieldCallback={({ polarization }) =>
            props.onChange(polarization)
          }
          // updatePolarizationHistory={updatePolarizationHistory}
          polarization={props.value}
          // polarizationHistory={polarizationHistory}
          gridColumnXs={12}
          isSweep
          hideLabel
        />
      );
    }
  },
  {
    title: "Quantity",
    field: "quantity",
    editComponent: props => {
      return (
        <DrilldownInput
          marginTop={0}
          value={props.value}
          options={[
            [
              {
                text: "field_coeff",
                isSelected: props.value === "field_coeff",
                next: false
              },
              {
                text: "power_coeff",
                isSelected: props.value === "power_coeff",
                next: false
              },
              {
                text: "Sx",
                isSelected: props.value === "Sx",
                next: false
              },
              {
                text: "Sy",
                isSelected: props.value === "Sy",
                next: false
              },
              {
                text: "Sz",
                isSelected: props.value === "Sz",
                next: false
              },
              {
                text: "Hx",
                isSelected: props.value === "Hx",
                next: false
              },
              {
                text: "Hy",
                isSelected: props.value === "Hy",
                next: false
              },
              {
                text: "Hz",
                isSelected: props.value === "Hz",
                next: false
              },
              {
                text: "Ex",
                isSelected: props.value === "Ex",
                next: false
              },
              {
                text: "Ey",
                isSelected: props.value === "Ey",
                next: false
              },
              {
                text: "Ez",
                isSelected: props.value === "Ez",
                next: false
              }
            ]
          ]}
          onClose={props.onChange}
        />
      );
    }
  },
  {
    title: "Representation",
    field: "representation",
    editComponent: props => {
      return (
        <DrilldownInput
          marginTop={0}
          value={props.value}
          options={[
            [
              {
                text: "Abs",
                isSelected: props.value === "Abs",
                next: false
              },
              {
                text: "Phase",
                isSelected: props.value === "Phase",
                next: false
              },
              {
                text: "Re",
                isSelected: props.value === "Re",
                next: false
              },
              {
                text: "Im",
                isSelected: props.value === "Im",
                next: false
              }
            ]
          ]}
          onClose={props.onChange}
        />
      );
    }
  },
  {
    title: "Value",
    field: "value",
    editComponent: props => {
      return (
        <SweepInput
          name="OutputSettingsValue"
          disabled={false}
          value={props.value ? props.value : ""}
          onChange={props.onChange}
          allowNegative={
            props.rowData.representation === "Phase" ? true : false
          }
        />
      );
    }
  },
  {
    title: "Weight",
    field: "weight",
    editComponent: props => {
      return (
        <SweepInput
          name="OutputSettingsWeight"
          disabled={false}
          value={props.value ? props.value : ""}
          onChange={props.onChange}
        />
      );
    }
  }
];

export class OutputSettingsTable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedOutputSettingId: null,
      error: null
    };
  }

  selectOutputSetting = id => {
    this.setState({
      selectedOutputSettingId: id
    });
  };

  getSelectedEntityId() {
    const { selectedOutputSettingId } = this.state;
    return selectedOutputSettingId;
  }

  onOutputSettingUpdate = newData => {
    return new Promise((resolve, reject) => {
      const { updateOutputSetting, fetchOutputSettingsAction } = this.props;
      updateOutputSetting(newData)
        .then(() => {
          fetchOutputSettingsAction(newData.simulation).then(() => resolve());
        })
        .catch(e => {
          this.setState({
            error: e.response?.data ? e.response?.data[0] : null
          });
          reject();
        });
    });
  };

  onOutputSettingAdd = newData => {
    return new Promise((resolve, reject) => {
      const { createOutputSetting, fetchOutputSettingsAction } = this.props;
      createOutputSetting(newData)
        .then(() => {
          fetchOutputSettingsAction(newData.simulation).then(() => resolve());
        })
        .catch(e => {
          this.setState({
            error: e.response?.data ? e.response?.data[0] : null
          });
          reject();
        });
    });
  };

  onOutputSettingDelete = oldData => {
    return new Promise((resolve, reject) => {
      const { deleteOutputSetting, fetchOutputSettingsAction } = this.props;
      deleteOutputSetting(oldData.id)
        .then(() => {
          fetchOutputSettingsAction(oldData.simulation).then(() => resolve());
        })
        .catch(() => {
          reject();
        });
    });
  };

  render = () => {
    const { outputSettings, classes } = this.props;
    return (
      <div>
        <EnhancedMaterialTable
          options={{
            search: false,
            paging: false,
            sorting: false,
            draggable: false,
            maxBodyHeight: 350,
            actionsColumnIndex: -1
          }}
          columns={getColumns()}
          data={outputSettings}
          title="Output Settings"
          onRowClick={(event, rowData) => this.selectOutputSetting(rowData.id)}
          selectedEntityId={this.getSelectedEntityId()}
          onEntityUpdate={this.onOutputSettingUpdate}
          classes={classes}
          editable={{
            onRowUpdate: this.onOutputSettingUpdate,
            onRowDelete:
              outputSettings.length > 1 ? this.onOutputSettingDelete : null,
            onRowAdd: this.onOutputSettingAdd
          }}
        />
        {this.state.error !== null && (
          <DirectionSnackbar message={this.state.error} />
        )}
      </div>
    );
  };
}

export default withErrorBoundary(withStyles(styles)(OutputSettingsTable));
