import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import EditActions from "components/EditActions/EditActions";
import MembersView from "./components/MembersView/MembersView";
import MembersSelection from "./components/MembersSelection/MembersSelection";
import DirectoryExplorerSelector from "MetaComponent/selectors/DirectoryExplorer";
import DirectoryExplorerApi from "MetaComponent/api/DirectoryExplorer";
import { FamilySelector } from "MetaCell/selectors/Family";
import IconTooltip from "components/IconTooltip/IconTooltip";
import FamilyApi from "MetaCell/api/Family";
import DirectionSnackbar from "components/Snackbar/Snackbar";
import ConfirmDialogAction from "BaseApp/actions/ConfirmDialog";
import DesignTargetApi from "MetaComponent/api/DesignTarget";
import SelectedDesignTargetSelector from "MetaComponent/selectors/SelectedDesignTarget";
import DesignTargetSelector from "MetaComponent/selectors/DesignTarget";
import DesignTargetHelper from "MetaComponent/helper/DesignTarget";

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    margin: 30
  },
  title: {
    float: "left"
  }
});

/**
 * A class component to connect to redux state and serve the form. See {@link MetaCellGlobalParametersForm}
 * @typedef {Component} MetaCellGlobalParameters
 * @author Akira Kotsugai
 */
export class ComponentMembers extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editingSelectedFM: null,
      saving: false,
      snackbar: {
        visible: false,
        message: ""
      }
    };
  }

  /**
   * it updates the editing selected family members with new ones
   * @param {Number[]} newSelectedFM - an array of family member Id's
   * @callback
   */
  updateSelectedFM = newSelectedFM => {
    this.setState({
      editingSelectedFM: newSelectedFM
    });
  };

  /**
   * it changes the editing state with the open meta component selected family members
   */
  editSelectedFM = () => {
    const openMetaComponent = this.getOpenMetaComponent(),
      { selectedFM } = openMetaComponent;
    this.setState({
      editingSelectedFM: selectedFM ? selectedFM : []
    });
  };

  /**
   * it changes the editing state to null
   */
  cancelEditingSelectedFM = () => {
    this.setState({
      editingSelectedFM: null
    });
  };

  /**
   * @param {Object} metaComponent
   * @returns {Object} the family that is linked to the component
   */
  getComponentFamily(metaComponent) {
    const { families } = this.props;
    return { ...families.byId[metaComponent.family] };
  }

  checkCellDimensions = async (sdtObjs, componentDimensions) => {
    const designTargetObjs = sdtObjs.map(sdt =>
      this.props.designTargets
        ? this.props.designTargets.byId[sdt.design_target]
        : false
    );
    const designTargetsThatDontHaveFFWF = DesignTargetHelper.getDesignTargetsThatDontHaveFFWF(
      designTargetObjs
    );
    let thereAreDiscrepanciesToHandle = false;
    let messages = [];

    if (designTargetsThatDontHaveFFWF.length > 0) {
      const { families, familyMembers } = this.props,
        { editingSelectedFM } = this.state;
      let upToDateDesignTargets = [];
      const familyMemberFamily =
        families.byId[familyMembers.byId[editingSelectedFM[0]].family];
      for (const dt of designTargetsThatDontHaveFFWF) {
        const upToDateDT = await DesignTargetApi.requestDesignTargets(dt.id);
        upToDateDesignTargets.push(...upToDateDT);
      }
      messages = await DesignTargetHelper.getDiscrepantDesignTargetNFWFsMessages(
        upToDateDesignTargets,
        familyMemberFamily,
        componentDimensions
      );
      thereAreDiscrepanciesToHandle = messages.some(message => message !== "");
    }
    return new Promise(resolve => {
      if (thereAreDiscrepanciesToHandle) {
        const { showConfirmDialog } = this.props;
        showConfirmDialog(
          "Meta component size and near-field wavefront size",
          messages,
          () => resolve(true),
          () => resolve(false),
          false,
          () => resolve(false),
          undefined,
          false
        );
      } else {
        resolve(true);
      }
    });
  };

  /**
   * it saves the selected family members and leaves the editing mode
   */
  save = async () => {
    const {
        updateMetaComponent,
        openMetaComponentId,
        familyMembers,
        fetchFamiliesAction,
        fetchFamilyMembersAction,
        selectedDesignTargets
      } = this.props,
      { editingSelectedFM } = this.state,
      openMetaComponent = this.getOpenMetaComponent(),
      { selected_design_targets, width, height, unit } = openMetaComponent,
      componentDimensions = { width, height, unit },
      sdtObjs = selected_design_targets.map(dtId =>
        selectedDesignTargets ? selectedDesignTargets.byId[dtId] : false
      );
    this.setState({ saving: true, snackbar: { message: "", visible: false } });

    const passedCheck = await this.checkCellDimensions(
      sdtObjs,
      componentDimensions
    );

    if (passedCheck) {
      try {
        await updateMetaComponent(openMetaComponentId, {
          selectedFM: editingSelectedFM,
          family:
            editingSelectedFM.length > 0
              ? familyMembers.byId[editingSelectedFM[0]].family
              : null
        });
        await fetchFamiliesAction();
        await fetchFamilyMembersAction();
      } catch (e) {
        const errorMessage = e?.response?.data[0];
        this.setState({
          snackbar: {
            message: errorMessage,
            visible: true
          }
        });
      }
    }

    this.cancelEditingSelectedFM();
    this.setState({
      saving: false
    });
  };

  /**
   * @returns {Object} the open meta component entity
   */
  getOpenMetaComponent = () => {
    const { openMetaComponentId, metaComponents } = this.props;
    return metaComponents.byId[openMetaComponentId];
  };

  /**
   * it gets the ids of the meta component's selected family members and builds an array
   * of family member objects from it
   * @returns {Object[]} an array of component members
   */
  getComponentMembers = () => {
    const { familyMembers } = this.props,
      openMetaComponent = this.getOpenMetaComponent(),
      { selectedFM } = openMetaComponent;
    return selectedFM
      .map(memberId => familyMembers.byId[memberId])
      .filter(member => member !== undefined); // don't include the deleted members
  };

  render() {
    const { classes, families } = this.props,
      { editingSelectedFM, saving } = this.state,
      isEditing = editingSelectedFM !== null,
      openMetaComponent = this.getOpenMetaComponent(),
      family = openMetaComponent.family
        ? families.byId[openMetaComponent.family]
        : null;
    return (
      <>
        <Paper className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div test-data="titleWrapper">
                <Typography
                  className={classes.title}
                  variant="h5"
                  component="h3"
                >
                  Component Selected Meta cells
                </Typography>
                <IconTooltip
                  text={
                    "Selected Meta cells (meta atoms) which will be used to build this component"
                  }
                />
              </div>
              <div style={{ float: "right" }}>
                <EditActions
                  isEditing={isEditing}
                  isSaving={saving}
                  onEdit={this.editSelectedFM}
                  onCancel={this.cancelEditingSelectedFM}
                  onSave={this.save}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              {isEditing ? (
                <MembersSelection
                  families={this.props.families}
                  editingSelectedFM={editingSelectedFM}
                  familyMembers={this.props.familyMembers}
                  onSelectFM={this.updateSelectedFM}
                />
              ) : (
                <MembersView
                  family={family}
                  membersList={this.getComponentMembers()}
                />
              )}
            </Grid>
          </Grid>
        </Paper>
        {this.state.snackbar.visible && (
          <DirectionSnackbar message={this.state.snackbar.message} />
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    openMetaComponentId: DirectoryExplorerSelector.getMetaComponentOpenId(
      state
    ),
    metaComponents: DirectoryExplorerSelector.getMetaComponents(state),
    familyMembers: FamilySelector.getFamilyMembers(state),
    families: FamilySelector.getFamilies(state),
    designTargets: DesignTargetSelector.getDesignTargets(state),
    selectedDesignTargets: SelectedDesignTargetSelector.getSelectedDesignTargets(
      state
    )
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateMetaComponent: (id, newProperties) =>
      dispatch(DirectoryExplorerApi.updateMetaComponent(id, newProperties)),
    fetchFamiliesAction: () => dispatch(FamilyApi.fetchFamilies()),
    fetchFamilyMembersAction: () => dispatch(FamilyApi.fetchFamilyMembers()),
    showConfirmDialog: (
      title,
      message,
      confirmAction,
      cancelAction,
      isReduxAction,
      postConfirm,
      inputPlaceholders,
      uploadFile,
      confirmLabels
    ) =>
      dispatch(
        ConfirmDialogAction.show(
          title,
          message,
          confirmAction,
          cancelAction,
          isReduxAction,
          postConfirm,
          inputPlaceholders,
          uploadFile,
          confirmLabels
        )
      )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorBoundary(withStyles(styles)(ComponentMembers)));
