import React, { Component } from "react";
import { Grid, MenuItem, FormLabel, FormControl } from "@material-ui/core";
import PropTypes from "prop-types";
import ContainerDimensions from "react-container-dimensions";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import Slider from "components/Slider/Slider";
import Simulation3DPlot from "components/Simulation3DPlot/Simulation3DPlot";
import NoDataPlot from "components/NoDataPlot/NoDataPlot";
import PlotMenu from "components/PlotMenu/PlotMenu";
import UnselfishSelect from "components/UnselfishSelect/UnselfishSelect";
import SideView from "MetaCell/containers/StructureCanvas/components/SideView";
import debounce from "lodash.debounce";

const quantityOptions = [
  "Ex",
  "Ey",
  "Ez",
  "Enorm",
  "Hx",
  "Hy",
  "Hz",
  "Hnorm",
  "Sx",
  "Sy",
  "Sz",
  "Snorm"
];

const representationOptions = ["Re", "Img", "Abs", "Phase"];

export class ModeAnalysisJobPlot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rangeValue: [1],
      quantity: "Ex",
      representation: "Re",
      crossSectionOrientation: "xz",
      crossSectionIndex: null,
      hideProbeSlider: false
    };
    this.onRangeChangeDebounced = debounce(this.onRangeChangeDebounced, 1000, {
      trailing: true
    });
  }

  componentDidMount() {
    this.buildInputs();
  }

  toggleProbeSliderVisibility = () => {
    var { hideProbeSlider } = this.state;
    this.setState({
      hideProbeSlider: !hideProbeSlider
    });
  };

  buildInputs() {
    const { zName } = this.props.data;
    let quantity, representation;
    if (zName) {
      [quantity, representation] = zName.split("/");
      this.setState({ quantity, representation });
    }
  }

  onUpdateButtonClick = () => {
    this.update();
  };

  update = () => {
    const {
      data: { id },
      onPlotBoxChange
    } = this.props;
    const { quantity, representation } = this.state;
    onPlotBoxChange(id, { zName: `${quantity}/${representation}` });
  };

  onRangeChangeDebounced = (id, rangeValueDebounced, rangeIndex) => {
    const { onRangeValueChange } = this.props;
    onRangeValueChange(id, rangeValueDebounced, rangeIndex);
  };

  onRangeChange = (rangeValue, index) => {
    let range = [...this.state.rangeValue];
    range[index] = rangeValue;
    const {
      data: { id }
    } = this.props;
    this.setState({ rangeValue: range });
    this.onRangeChangeDebounced(id, rangeValue, index);
  };

  onShowErrorsAndWarnings = () => {
    const { data, showErrorsAndWarnings } = this.props;
    const { postProcesLog } = data;
    const errors = postProcesLog.errors ? postProcesLog.errors : null;
    const warnings = postProcesLog.warnings ? postProcesLog.warnings : null;
    showErrorsAndWarnings(errors, warnings);
  };

  getProbePosition() {
    const {
      data: { sliderData },
      getProbePosition
    } = this.props;
    const { rangeValue } = this.state;
    return getProbePosition(sliderData.length ? rangeValue - 1 : 0);
  }

  handlePlotCrossSection = async (orientation, index) => {
    let plotId = this.props.data?.id;
    if (plotId) {
      return await this.props.handleCrossSection(plotId, orientation, index);
    }
  };

  render = () => {
    const {
      crossSectionOrientation,
      crossSectionIndex,
      hideProbeSlider
    } = this.state;
    const {
        data: {
          id,
          xData,
          yData,
          zData,
          sliderData,
          xName,
          yName,
          zName,
          polling,
          rangeValue,
          status,
          sliderUnit,
          xUnit,
          yUnit,
          zUnit
        }
      } = this.props,
      hasData = xData.length > 0 && yData.length > 0 && zData.length > 0;
    return (
      <div style={{ width: "100%" }}>
        <Grid container direction="row" spacing={5}>
          <Grid item>
            <Grid container>
              <Grid item xs={6}>
                <FormControl>
                  <FormLabel style={{ fontSize: 12 }}>Quantity</FormLabel>
                  <UnselfishSelect
                    test-data="quantity"
                    style={{ marginTop: 4 }}
                    label="Quantity"
                    value={this.state.quantity}
                    onChange={event =>
                      this.setState({ quantity: event.target.value })
                    }
                  >
                    {quantityOptions.map(quantity => (
                      <MenuItem name="PolarizationOption" value={quantity}>
                        {quantity}
                      </MenuItem>
                    ))}
                  </UnselfishSelect>
                </FormControl>
                <FormControl>
                  <FormLabel style={{ fontSize: 12, marginTop: 4 }}>
                    Representation
                  </FormLabel>
                  <UnselfishSelect
                    test-data="representation"
                    style={{ marginTop: 4 }}
                    value={this.state.representation}
                    onChange={event =>
                      this.setState({ representation: event.target.value })
                    }
                  >
                    {representationOptions.map(representation => (
                      <MenuItem
                        name="PolarizationOption"
                        value={representation}
                      >
                        {representation}
                      </MenuItem>
                    ))}
                  </UnselfishSelect>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center"
                  }}
                >
                  <PlotMenu
                    showErrorsAndWarnings={this.onShowErrorsAndWarnings}
                    onUpdateButtonClick={this.onUpdateButtonClick}
                    polling={polling}
                    status={status}
                    plotId={id}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <div
              id={`plotbox_${id}`}
              className="plotContainer"
              test-data="plotContainer"
            >
              <div>
                <ContainerDimensions>
                  {({ width, height }) => {
                    if (!hasData) {
                      height = width / 3;
                    }
                    return (
                      <div
                        test-data="plotbox"
                        style={{
                          backgroundColor: "white",
                          position: "relative"
                        }}
                      >
                        {!hasData && <NoDataPlot height={height} />}
                        {hasData && (
                          <Simulation3DPlot
                            width={width}
                            xData={xData}
                            xName={xName}
                            xUnit={xUnit}
                            yData={yData}
                            yName={yName}
                            yUnit={yUnit}
                            zData={zData}
                            zName={zName}
                            zUnit={zUnit}
                            rangeValue={rangeValue}
                            enableCrossSection
                            handleCrossSection={this.handlePlotCrossSection}
                            crossSectionOrientation={crossSectionOrientation}
                            crossSectionIndex={crossSectionIndex}
                            crossSectionRowsCount={yData.length}
                            crossSectionColumnsCount={xData.length}
                            crossSection3DUnit={xUnit}
                            crossSectionColorbarUnit={`${zName} (${zUnit})`}
                            toggleProbeSliderVisibility={
                              this.toggleProbeSliderVisibility
                            }
                          />
                        )}
                      </div>
                    );
                  }}
                </ContainerDimensions>
              </div>

              {hasData && !hideProbeSlider && sliderData.length > 0 && (
                <Slider
                  rangeValue={this.state.rangeValue[0]}
                  sliderData={sliderData[0]} //will be changed if we introduce multisliders in MA, refer plotbox.jsx <Slider>
                  onRangeChange={val => this.onRangeChange(val, 0)}
                  sliderName={this.props.probeAlias || "Probe Position"}
                  sliderUnit={sliderUnit}
                />
              )}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div style={{ width: "50%", margin: "auto" }}>
              <SideView
                sweptVariables={this.props.sweptVariables}
                sortedLayers={this.props.sortedLayers}
                probePosition={this.getProbePosition()}
                selectedLayerId={this.props.selectedLayerId}
                getLayerColors={this.props.getLayerColors}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };
}

ModeAnalysisJobPlot.propTypes = {
  params: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  onPlotboxChange: PropTypes.func.isRequired,
  onRangeValueChange: PropTypes.func.isRequired
};

export default withErrorBoundary(ModeAnalysisJobPlot);
