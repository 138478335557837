import React, { PureComponent } from "react";
import {
  Grid,
  Paper,
  Typography,
  withStyles,
  FormLabel
} from "@material-ui/core";
import DrilldownInput from "components/DrilldownInput";
import DirectionSnackbar from "components/Snackbar/Snackbar";
import FFWFTarget from "./components/FFWFTarget/FFWFTarget";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import { connect } from "react-redux";
import ConfirmDialogAction from "BaseApp/actions/ConfirmDialog";
import { isEqual } from "lodash";
import DesignTargetApi from "MetaComponent/api/DesignTarget";
import DesignTargetHelper from "MetaComponent/helper/DesignTarget";
import Script2DSelector from "MetaComponent/selectors/Script2D";
import { FamilySelector } from "MetaCell/selectors/Family";

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  title: {
    float: "left"
  },
  drilldown: {
    float: "left"
  }
});

/**
 * a component to allow the user to select the far field wavefront target of a design target
 * @author Akira Kotsugai
 */
export class FarFieldWavefront extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editingSelectedFFWFTargetId: -1,
      selectedDesignTargetId: 1
    };
  }

  /**
   * it updates the editing ffwf target id with a new value
   * @param {Number} newId - the new ffwf target id
   * @callback
   */
  updateEditingFFWFTargetId = newId => {
    this.setState({
      editingSelectedFFWFTargetId: newId
    });
  };

  /**
   * @returns {Object} the focused design target object
   */
  getFocusedDesignTarget = () => {
    return this.props.designTargets.byId[this.props.focusedDesignTargetId];
  };

  /**
   * it changes the editing state with the design target FFWFTarget
   */
  editSelectedFFWFTarget = () => {
    const selectedDesignTarget = this.getFocusedDesignTarget(),
      { FFWFTarget } = selectedDesignTarget;
    this.setState({
      editingSelectedFFWFTargetId: FFWFTarget
    });
  };

  /**
   * it saves the design target's selected FFWFTarget and leaves the editing mode
   */
  saveDesignTargetSelectedFFWFTarget = editingSelectedFFWFTargetId => {
    const { focusedDesignTargetId, updateDesignTargetAction } = this.props;
    updateDesignTargetAction(focusedDesignTargetId, {
      FFWFTarget: editingSelectedFFWFTargetId
    });
  };

  /**
   * it creates drill down options from the ffwftargets by mixing fixed options
   * with database options.
   * @returns {Object[][]} the drilldown options
   */
  getDrilldownOptions() {
    const staticOptions = [
        {
          text: "Create new",
          isSelected: false
        },
        {
          text: "None",
          isSelected: false
        }
      ],
      dynamicOptions = Object.values(this.props.ffwfTargets.byId).map(
        ffwfTarget => ({
          text: ffwfTarget.name,
          isSelected: false
        })
      );
    return [[...staticOptions, ...dynamicOptions]];
  }

  /**
   * @returns {String} the text None or the ffwf target name depending on what is selected
   */
  getDrilldownValue() {
    if (this.activeFFWFTargetIsNone()) {
      return "None";
    }
    const { editingSelectedFFWFTargetId } = this.state,
      isEditing = editingSelectedFFWFTargetId !== -1,
      selectedDesignTarget = this.getFocusedDesignTarget();
    if (editingSelectedFFWFTargetId === undefined) {
      return "Create new";
    }
    return this.props.ffwfTargets.byId[
      isEditing ? editingSelectedFFWFTargetId : selectedDesignTarget.FFWFTarget
    ].name;
  }

  componentDidUpdate(prevProps) {
    if (this.props.ffwfTargets !== prevProps.ffwfTargets) {
      if (this.state.editingSelectedFFWFTargetId === undefined) {
        this.onFFWFTargetSelect(
          Object.values(this.props.ffwfTargets.byId).length + 1
        );
      }
    }
  }

  /**
   * it sets the selected ffwf target id in the state
   * we decrement the selected option index because the first two options are static.
   * it is supposed to be passed as an "on change callback" to the drilldown component.
   * @param {String} selectedOptionIndex - the index of the selected option
   * @callback
   */
  onFFWFTargetSelect = selectedOptionIndex => {
    const createNewWasSelected = selectedOptionIndex === 0,
      noneWasSelected = selectedOptionIndex === 1;
    let editingSelectedFFWFTargetId;
    if (createNewWasSelected) {
      editingSelectedFFWFTargetId = undefined;
    } else if (noneWasSelected) {
      editingSelectedFFWFTargetId = null;
    } else {
      editingSelectedFFWFTargetId = Object.values(this.props.ffwfTargets.byId)[
        selectedOptionIndex - 2
      ].id;
    }
    this.setState({ editingSelectedFFWFTargetId });
    this.saveDesignTargetSelectedFFWFTarget(editingSelectedFFWFTargetId);
  };

  /**
   * @returns {Boolean} whether the active ffwft target is none or not
   */
  activeFFWFTargetIsNone() {
    const { editingSelectedFFWFTargetId } = this.state,
      isEditing = editingSelectedFFWFTargetId !== -1,
      selectedDesignTarget = this.getFocusedDesignTarget();
    return (
      (isEditing && editingSelectedFFWFTargetId === null) ||
      (!isEditing && selectedDesignTarget.FFWFTarget === null)
    );
  }

  getComponentFamily(metaComponent) {
    const { families } = this.props;
    return { ...families.byId[metaComponent.family] };
  }

  /**
   * it checks whether it is create or update mode and call the correct action,
   * in case it is update mode, it takes only the differences between the original
   * and the editing ffwf target and calls an action to update these properties.
   * @param {Object} editingFFWFTarget - the editing ffwf target values
   * @callback
   */
  saveFFWFTarget = (editingFFWFTarget, onFinish = () => {}) => {
    const {
        ffwfTargets,
        updateFFWFTargetAction,
        createFFWFTargetAction,
        openMetaComponent
      } = this.props,
      { id } = editingFFWFTarget,
      updatingAnExistingFFWFTarget = id !== undefined;
    const dimensions = {
      componentWidth: openMetaComponent && openMetaComponent.width,
      componentHeight: openMetaComponent && openMetaComponent.height,
      cellWidth: DesignTargetHelper.getComponentDimensionfromMetacellDim(
        openMetaComponent &&
          this.getComponentFamily(openMetaComponent).cell_width,
        openMetaComponent && openMetaComponent.unit,
        openMetaComponent && this.getComponentFamily(openMetaComponent).unit
      ),
      cellHeight: DesignTargetHelper.getComponentDimensionfromMetacellDim(
        openMetaComponent &&
          this.getComponentFamily(openMetaComponent).cell_height,
        openMetaComponent && openMetaComponent.unit,
        openMetaComponent && this.getComponentFamily(openMetaComponent).unit
      )
    };
    if (updatingAnExistingFFWFTarget) {
      const originalFFWFTarget = ffwfTargets.byId[id],
        ffwfTargetPropertyNames = Object.keys(editingFFWFTarget);
      let objWithFFWFTargetChanges = {};
      ffwfTargetPropertyNames.forEach(property => {
        if (
          !isEqual(editingFFWFTarget[property], originalFFWFTarget[property])
        ) {
          objWithFFWFTargetChanges[property] = editingFFWFTarget[property];
        }
      });
      return updateFFWFTargetAction(
        id,
        objWithFFWFTargetChanges,
        dimensions
      ).then(() => onFinish());
    } else {
      return createFFWFTargetAction(editingFFWFTarget, dimensions);
    }
  };

  render() {
    const { classes, script2Ds } = this.props,
      { editingSelectedFFWFTargetId } = this.state,
      isEditing = editingSelectedFFWFTargetId !== -1,
      isCreateMode = editingSelectedFFWFTargetId === undefined,
      selectedDesignTarget = this.getFocusedDesignTarget();
    return selectedDesignTarget ? (
      <Paper className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.title} variant="h5" component="h3">
              {`${selectedDesignTarget.name} far-field wavefront`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormLabel style={{ fontSize: 12 }}>Selected</FormLabel>
            <div>
              <DrilldownInput
                className={classes.drilldown}
                marginTop={0}
                float={"left"}
                value={this.getDrilldownValue()}
                options={this.getDrilldownOptions()}
                onSelect={this.onFFWFTargetSelect}
                showCount={false}
                disabled={false}
              />
            </div>
            {!this.activeFFWFTargetIsNone() && (
              <FFWFTarget
                ffwfTarget={
                  isCreateMode
                    ? null
                    : this.props.ffwfTargets.byId[
                        isEditing
                          ? editingSelectedFFWFTargetId
                          : selectedDesignTarget.FFWFTarget
                      ]
                }
                onSave={this.saveFFWFTarget}
                showConfirmDialog={this.props.showConfirmDialog}
                loadWavefront={this.props.getFFWFTargetWavefrontAction}
                rowLayout={true}
                sampleScripts={Object.values(script2Ds.byId).filter(
                  script => script.type === "FFWF_EXAMPLE"
                )}
                useWavefrontInternally={this.props.useWavefrontInternally}
              />
            )}
          </Grid>
        </Grid>
        {editingSelectedFFWFTargetId === null && (
          <DirectionSnackbar
            message={"Near-field wavefront must be set/updated!"}
          />
        )}
      </Paper>
    ) : null;
  }
}

const mapStateToProps = state => ({
  script2Ds: Script2DSelector.getScript2Ds(state),
  families: FamilySelector.getFamilies(state)
});

const mapDispatchToProps = dispatch => {
  return {
    showConfirmDialog: (
      title,
      message,
      confirmAction,
      cancelAction,
      isReduxAction
    ) =>
      dispatch(
        ConfirmDialogAction.show(
          title,
          message,
          confirmAction,
          cancelAction,
          isReduxAction
        )
      ),
    updateFFWFTargetAction: (id, properties, dimensions) =>
      dispatch(DesignTargetApi.updateFFWFTarget(id, properties, dimensions)),
    getFFWFTargetWavefrontAction: id =>
      dispatch(DesignTargetApi.getFFWFTargetWavefront(id)),
    createFFWFTargetAction: ffwfTarget =>
      dispatch(DesignTargetApi.createFFWFTarget(ffwfTarget)),
    updateDesignTargetAction: (id, properties) =>
      dispatch(DesignTargetApi.updateDesignTarget(id, properties))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorBoundary(withStyles(styles)(FarFieldWavefront)));
