import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import SweepInput, { disablingType } from "components/SweepInput/SweepInput";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import HelpIcon from "@material-ui/icons/Help";
import Tooltip from "@material-ui/core/Tooltip";
import { withErrorBoundary } from "BaseApp/ErrorBoundary/ErrorBoundary";
import { TextField, FormLabel } from "@material-ui/core";
import UnselfishSelect from "components/UnselfishSelect/UnselfishSelect";

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: 400,
    margin: "auto"
  },
  container: {
    alignItems: "center",
    justifyContent: "center"
  },
  label: {
    color: "#0000008a",
    display: "flex",
    alignItems: "center"
  },
  labelIcon: {
    fontSize: 16,
    marginLeft: 3,
    color: "#3f51b5"
  }
});

/**
 * A class component decoupled from the incident lights to be used throughout the application
 * not tested because it was not tested before the decoupling. @todo
 */
export class Polarization extends Component {
  polarizationSelect;
  /**
   * It handles a change in a field when it is being edited and calls an action
   * @param {Object} event - the change event
   */
  handleUpdateField = value => {
    const {
      updateFieldCallback,
      updatePolarizationHistory,
      polarization,
      polarizationHistory
    } = this.props;

    if (value === "Jones") {
      value = (polarizationHistory && polarizationHistory.jones) || "0/0/0";
    } else if (value === "Stokes") {
      value = (polarizationHistory && polarizationHistory.stokes) || "1/0/0/0";
    } else {
      if (updatePolarizationHistory) {
        if (this.polarizationSelect === "Jones") {
          updatePolarizationHistory({ jones: polarization });
        } else if (this.polarizationSelect === "Stokes") {
          updatePolarizationHistory({ stokes: polarization });
        }
      }
    }

    updateFieldCallback({
      polarization: value
    });
  };

  getInputComponent = (
    inputName,
    inputIndex,
    polarizationFields,
    isSweep,
    disabled
  ) => {
    return isSweep ? (
      <SweepInput
        name={inputName}
        value={polarizationFields[inputIndex]}
        onChange={val => {
          polarizationFields[inputIndex] = val;
          this.handleUpdateField(polarizationFields.join("/"));
        }}
        disabled={
          inputName === "Stokes1"
            ? disablingType.DISABLE_INPUT
            : disabled && disablingType.DISABLE_INPUT
        }
        simulationId={this.props.simulationId}
        sweptVariables={this.props.sweptVariables}
        allowOnTheFlyVariableCreation={true}
        allowNegative
      />
    ) : (
      <TextField
        name={inputName}
        value={polarizationFields[inputIndex]}
        onChange={event => {
          polarizationFields[inputIndex] = event.target.value;
          this.handleUpdateField(polarizationFields.join("/"));
        }}
        disabled={inputName === "Stokes1" ? true : disabled}
      />
    );
  };

  render() {
    const {
        classes,
        polarization,
        gridColumnXs,
        disabled,
        isSweep,
        hideLabel
      } = this.props,
      polarizationFields = (polarization && polarization.split("/")) || [];
    this.polarizationSelect =
      polarizationFields.length === 3
        ? "Jones"
        : polarizationFields.length === 4
        ? "Stokes"
        : polarization;
    return (
      <>
        <Grid item xs={gridColumnXs}>
          <div>
            <FormControl
              name="IncidentLightPolarization"
              style={{ width: "100%" }}
            >
              {!hideLabel && (
                <FormLabel disabled={disabled} style={{ fontSize: 12 }}>
                  Polarization
                </FormLabel>
              )}
              <UnselfishSelect
                style={{ marginTop: 4 }}
                name="IncidentLightPolarization"
                test-data="polarization"
                value={this.polarizationSelect}
                onChange={event => {
                  this.handleUpdateField(event.target.value);
                }}
                disabled={disabled}
                error={this.props.error}
              >
                <MenuItem name="PolarizationOption" value="TE">
                  TE
                </MenuItem>
                <MenuItem name="PolarizationOption" value="TM">
                  TM
                </MenuItem>
                <MenuItem name="PolarizationOption" value="LCP">
                  LCP
                </MenuItem>
                <MenuItem name="PolarizationOption" value="RCP">
                  RCP
                </MenuItem>
                <MenuItem name="PolarizationOption" value="Jones">
                  Jones
                </MenuItem>
                <MenuItem name="PolarizationOption" value="Stokes">
                  Stokes
                </MenuItem>
              </UnselfishSelect>
            </FormControl>
          </div>
        </Grid>

        <Grid item xs={gridColumnXs}>
          {polarizationFields.length === 3 && (
            <>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl style={{ width: "100%" }}>
                    <div className={classes.label}>
                      Jx
                      <Tooltip
                        title="Amplitude of the x component"
                        placement="bottom"
                      >
                        <HelpIcon className={classes.labelIcon} />
                      </Tooltip>
                    </div>
                    {this.getInputComponent(
                      "JonesX",
                      0,
                      polarizationFields,
                      isSweep,
                      disabled
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl style={{ width: "100%" }}>
                    <div className={classes.label}>
                      Jy
                      <Tooltip
                        title="Amplitude of the y component"
                        placement="bottom"
                      >
                        <HelpIcon className={classes.labelIcon} />
                      </Tooltip>
                    </div>
                    {this.getInputComponent(
                      "JonesY",
                      1,
                      polarizationFields,
                      isSweep,
                      disabled
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl style={{ width: "100%" }}>
                    <div className={classes.label}>
                      &#916; Phase
                      <Tooltip
                        title="Phase difference between the x and the y component in deg"
                        placement="bottom"
                      >
                        <HelpIcon className={classes.labelIcon} />
                      </Tooltip>
                    </div>
                    {this.getInputComponent(
                      "JonesPhase",
                      2,
                      polarizationFields,
                      isSweep,
                      disabled
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </>
          )}

          {polarizationFields.length === 4 && (
            <>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl style={{ width: "100%" }}>
                    <div className={classes.label}>
                      S0
                      <Tooltip title="Total intensity" placement="bottom">
                        <HelpIcon className={classes.labelIcon} />
                      </Tooltip>
                    </div>
                    {this.getInputComponent(
                      "Stokes1",
                      0,
                      polarizationFields,
                      isSweep,
                      disabled
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl style={{ width: "100%" }}>
                    <div className={classes.label}>
                      S1
                      <Tooltip title="Linearly polarized" placement="bottom">
                        <HelpIcon className={classes.labelIcon} />
                      </Tooltip>
                    </div>
                    {this.getInputComponent(
                      "Stokes2",
                      1,
                      polarizationFields,
                      isSweep,
                      disabled
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl style={{ width: "100%" }}>
                    <div className={classes.label}>
                      S2
                      <Tooltip title="Polarized under 45°" placement="bottom">
                        <HelpIcon className={classes.labelIcon} />
                      </Tooltip>
                    </div>
                    {this.getInputComponent(
                      "Stokes3",
                      2,
                      polarizationFields,
                      isSweep,
                      disabled
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl style={{ width: "100%" }}>
                    <div className={classes.label}>
                      S3
                      <Tooltip title="Circularly polarized" placement="bottom">
                        <HelpIcon className={classes.labelIcon} />
                      </Tooltip>
                    </div>
                    {this.getInputComponent(
                      "Stokes4",
                      3,
                      polarizationFields,
                      isSweep,
                      disabled
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </>
    );
  }
}

export default withErrorBoundary(withStyles(styles)(Polarization));
