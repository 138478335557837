import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import DesignSelector from "MetaComponent/selectors/Design";

export const styles = {
  left: {
    paddingRight: 260
  }
};

export class DesignJobProgress extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { classes, designJobs, selectedJobId } = this.props;
    return <div>{selectedJobId && designJobs.byId[selectedJobId].status}</div>;
  }
}

const mapStateToProps = state => {
  return {
    designJobs: DesignSelector.getDesignJobs(state),
    selectedJobId: DesignSelector.getSelectedJobId(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DesignJobProgress));
