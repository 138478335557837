import React, { PureComponent } from "react";
import {
  withStyles,
  FormLabel,
  TextField,
  Select,
  MenuItem,
  Grid
} from "@material-ui/core";
import Wavefront from "MetaComponent/containers/TargetCanvas/components/Wavefront/Wavefront";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import DesignTargetApi from "MetaComponent/api/DesignTarget";
import Helper from "MetaComponent/helper/FFWFTarget";
import GenericTextInput from "../../../../../../../../../components/GenericTextInput/GenericTextInput";
import IconTooltip from "components/IconTooltip/IconTooltip";
import center_coordinates from "assets/center_coordinates.png";
import alpha from "assets/alpha.png";
import beta from "assets/beta.png";
import gama_1 from "assets/gama_1.png";
import gama_2 from "assets/gama_2.png";
import NumberInput from "components/NumberInput/NumberInput";
import { connect } from "react-redux";
import HelperUtils from "MetaCell/helper/HelperUtils";
import debounce from "lodash.debounce";
import DirectionSnackbar from "components/Snackbar/Snackbar";

const styles = theme => ({
  textField: {
    width: "60%",
    marginBottom: 5
  },
  longTextField: {
    width: "100%",
    marginBottom: 5
  },
  groupThreeNumberFields: {
    width: "30%",
    marginBottom: 5
  },
  groupTwoNumberFields: {
    width: "45%",
    marginBottom: 5
  },
  groupLabel: {
    fontSize: 12,
    marginBottom: 5
  }
});

const requiredFieldText = "required";

const targetFormSchema = Yup.object().shape({
  name: Yup.string().required(requiredFieldText),
  centerCoordX: Yup.string().required(requiredFieldText),
  centerCoordY: Yup.string().required(requiredFieldText),
  centerCoordZ: Yup.string().required(requiredFieldText),
  angular_center_angle_x: Yup.string().required(requiredFieldText),
  angular_center_angle_y: Yup.string().required(requiredFieldText),
  // alpha: Yup.string().required(requiredFieldText),
  // beta: Yup.string().required(requiredFieldText),
  // gama: Yup.string().required(requiredFieldText),
  WFWidth: Yup.string().required(requiredFieldText),
  WFHeight: Yup.string().required(requiredFieldText),
  opening_angle_x: Yup.number()
    .required(requiredFieldText)
    .moreThan(0, "should be greater than 0"),
  opening_angle_y: Yup.number()
    .required(requiredFieldText)
    .moreThan(0, "should be greater than 0"),
  iteration_Stop: Yup.number("Must be a number")
    .required("Is required")
    .integer()
    .min(1, "Min is 1"),
  tolerance: Yup.string().required(requiredFieldText)
});

/**
 * a component to allow a ffwf target to be edited or created
 * @author Akira Kotsugai
 */
export class FFWFTargetForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showImportFailed: false,
      snackbar: {
        visible: false,
        message: ""
      }
    };
  }

  /**
   * if there is an existing ffwf target object, the initial
   * form values will consist of a copy of the target object,
   * otherwise, it will be empty (create mode).
   * @returns {Object} the form values
   */
  getFormInitialValues() {
    const { ffwfTarget } = this.props;
    if (ffwfTarget) {
      return {
        ...ffwfTarget,
        centerCoordX: ffwfTarget.center_coord[0],
        centerCoordY: ffwfTarget.center_coord[1],
        centerCoordZ: ffwfTarget.center_coord[2],
        alpha: ffwfTarget.euler_Angles[0],
        beta: ffwfTarget.euler_Angles[1],
        gama: ffwfTarget.euler_Angles[2],
        angular_center_angle_x: ffwfTarget.angular_center_angles[0],
        angular_center_angle_y: ffwfTarget.angular_center_angles[1],
        opening_angle_x: ffwfTarget.opening_angles[0],
        opening_angle_y: ffwfTarget.opening_angles[1]
      };
    }
    return {
      name: "",
      description: "",
      centerCoordX: 0,
      centerCoordY: 0,
      centerCoordZ: 100,
      alpha: 0,
      beta: 0,
      gama: 0,
      WFWidth: 100,
      WFHeight: 100,
      tolerance: 1e-4,
      iteration_Stop: 100,
      wave_front: null,
      wavefrontFile: null,
      unit: "μm",
      target_quantity: "cartesian",
      angular_center_angle_x: 0,
      angular_center_angle_y: 0,
      opening_angle_x: 180,
      opening_angle_y: 180,
      angular_unit: "degrees"
    };
  }

  getAsyncFFWFImportStatusWithDelay = debounce(
    this.getAsyncFFWFImportStatus,
    5000
  );

  /**
   * before calling the parent submitter, it prepares the array datas, which are
   * the orientation and the center coordinates, and only sends the wavefront File, not the
   * wavefront object, because the object is only used in the representation.
   * @param {Object} values - the form values to submit
   */
  handleSubmit = values => {
    const {
      target_quantity,
      centerCoordX,
      centerCoordY,
      centerCoordZ,
      alpha,
      beta,
      gama,
      angular_center_angle_x,
      angular_center_angle_y,
      opening_angle_x,
      opening_angle_y
    } = values;
    let preparedValues = { ...values };
    // we delete this field because we submit the file, not the wavefront object
    delete preparedValues.wave_front;
    preparedValues["center_coord"] = [centerCoordX, centerCoordY, centerCoordZ];
    preparedValues["euler_Angles"] = [alpha, beta, gama];
    preparedValues["angular_center_angles"] = [
      angular_center_angle_x,
      angular_center_angle_y
    ];
    preparedValues["opening_angles"] = [opening_angle_x, opening_angle_y];
    delete preparedValues.centerCoordX;
    delete preparedValues.centerCoordY;
    delete preparedValues.centerCoordZ;
    delete preparedValues.angular_center_angle_x;
    delete preparedValues.angular_center_angle_y;
    delete preparedValues.opening_angle_x;
    delete preparedValues.opening_angle_y;
    delete preparedValues.alpha;
    delete preparedValues.beta;
    delete preparedValues.gama;
    this.props.onSubmit(preparedValues);
  };

  /**
   * it exports the wavefront json of the active ffwf target.
   * supposed to be passed down to the wavefront component
   * @callback
   */
  exportWavefront = () => {
    const { ffwfTarget } = this.props;
    DesignTargetApi.exportFFWFTargetWavefront(ffwfTarget.id)
      .then(data => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${ffwfTarget.name}_wavefront.json`);
        link.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => console.log(error));
  };

  /**
   *
   * @param {File} wavefrontFile -the wavefront file
   * it reads the wavefront file and the read data is expected to be the amplitude.
   * phase is faked because the user still dont use it.
   * @returns {Object} the wavefront as javascript data
   */
  getWavefrontObject = async wavefrontFile => {
    const fileData = await Helper.readWavefrontFile(wavefrontFile);
    let amplitude;
    if (fileData.amplitude) {
      amplitude = fileData.amplitude;
    } else {
      amplitude = fileData; // backward compatibility
    }
    const phase = [
        "FAKE DATA BECAUSE WE MUST HAVE AMPLITUDE AND PHASE BUT CURRENTLY PHASE IS NOT IMPLEMENTED, SOON WE'LL EXPECT PHASE TO BE INCLUDED IN THE JSON"
      ],
      wavefrontObject = [amplitude, phase];
    return wavefrontObject;
  };

  /**
   * it submits the json file to the backend
   * @param {File} wavefrontFile - the json file
   * @callback
   */
  handleWavefrontFileImport = (wavefrontFile, onFinish) => {
    const { ffwfTarget, onSubmit } = this.props;
    onSubmit({ id: ffwfTarget.id, wavefrontFile }, onFinish);
  };

  getAsyncFFWFImportStatus(ffwfTargetId, onFinish) {
    return DesignTargetApi.getAsyncFFWFImportStatus(ffwfTargetId)
      .then(resp => resp.data)
      .then(data => {
        if (data.status === "QUEUED" || data.status === "RUNNING") {
          return this.getAsyncFFWFImportStatusWithDelay(ffwfTargetId, onFinish);
        } else if (data.status === "DONE" || data.status === "FAILED") {
          if (data.status === "DONE") {
            this.props.requestWavefront(ffwfTargetId);
          } else {
            this.setState({ showImportFailed: false }, () => {
              if (data.errors && data.errors["error"]) {
                this.setState({
                  snackbar: {
                    visible: true,
                    message: data.errors["error"]
                  }
                });
              } else {
                this.setState({ showImportFailed: true });
              }
            });
          }
          return onFinish();
        }
      });
  }

  /**
   * it creates a json file out of the amplitude data and submits it to the backend
   * @param {Object} amplitude - the generated wavefront data
   * @callback
   */
  handleWavefrontGeneratorConfirmation = (amplitude, onFinish) => {
    const { ffwfTarget, onSubmit } = this.props,
      wavefrontJson = JSON.stringify(amplitude),
      blob = new Blob([wavefrontJson], { type: "application/json" }),
      amplitudeFile = new File([blob], "file.json");
    onSubmit({ id: ffwfTarget.id, wavefrontFile: amplitudeFile }, onFinish);
  };

  getWavefrontWidth = ffwfTarget => {
    if (ffwfTarget.target_quantity === "cartesian") {
      return ffwfTarget.WFWidth;
    } else {
      // angular
      const opening_angle_x = ffwfTarget.opening_angles[0];
      return opening_angle_x;
    }
  };

  getWavefrontHeight = ffwfTarget => {
    if (ffwfTarget.target_quantity === "cartesian") {
      return ffwfTarget.WFHeight;
    } else {
      // angular
      const opening_angle_y = ffwfTarget.opening_angles[1];
      return opening_angle_y;
    }
  };

  getWavefrontUnit = ffwfTarget => {
    if (ffwfTarget.target_quantity === "cartesian") {
      return ffwfTarget.unit;
    } else {
      // angular
      return ffwfTarget.angular_unit;
    }
  };

  getWavefrontXLabel = ffwfTarget => {
    if (ffwfTarget.target_quantity === "cartesian") {
      return null;
    } else {
      // angular
      return "angle-x";
    }
  };

  getWavefrontYLabel = ffwfTarget => {
    if (ffwfTarget.target_quantity === "cartesian") {
      return null;
    } else {
      // angular
      return "angle-y";
    }
  };

  changeAngularUnit = (formValues, newUnit, fieldValueSetter) => {
    fieldValueSetter("angular_unit", newUnit);
    const formFieldsToBeScaled = [
      "angular_center_angle_x",
      "angular_center_angle_y",
      "opening_angle_x",
      "opening_angle_y"
    ];
    const previousUnit = formValues["angularUnit"];
    if (previousUnit !== newUnit) {
      const scaleFunction =
        newUnit === "radians"
          ? HelperUtils.toRadians
          : newUnit === "degrees"
          ? HelperUtils.toDegrees
          : (value, precision) => value;
      formFieldsToBeScaled.forEach(fieldToBeScaled => {
        const fieldValue = formValues[fieldToBeScaled];
        const fieldValueIsNumber = !isNaN(fieldValue);
        if (fieldValueIsNumber) {
          const newValue = scaleFunction(fieldValue);
          fieldValueSetter(fieldToBeScaled, newValue);
        }
      });
    }
  };

  generateAsyncWavefront = async (
    data,
    onFinish,
    onSuccessJobStart,
    onError
  ) => {
    const { ffwfTarget } = this.props;
    this.setState({
      snackbar: false,
      message: ""
    });
    await DesignTargetApi.startAsyncFfwfJob(ffwfTarget.id, data)
      .then(() => {
        onSuccessJobStart();
        this.getAsyncFFWFImportStatusWithDelay(ffwfTarget.id, onFinish);
      })
      .catch(e => {
        onError(e.response?.data || "Invalid code");
      });
  };

  render() {
    const { classes, ffwfTarget, isEditing, rowLayout } = this.props,
      isCreateMode = ffwfTarget === null,
      disabled = !isEditing && !isCreateMode;
    const { showImportFailed } = this.state;
    return (
      <>
        <>
          <Grid container spacing={2}>
            <Formik
              initialValues={this.getFormInitialValues()}
              enableReinitialize
              validationSchema={targetFormSchema}
              onSubmit={this.handleSubmit}
            >
              {({
                errors,
                values,
                handleChange,
                setFieldValue,
                handleBlur,
                touched,
                submitForm,
                resetForm
              }) => {
                // making it possible to call these formik functions from parent components
                this.props.bindSubmitForm(submitForm);
                this.props.bindResetForm(resetForm);
                if (!values.wave_front && this.props.wavefront)
                  values.wave_front = this.props.wavefront;
                return (
                  <Grid item xs={rowLayout ? 6 : 12}>
                    <Form>
                      <TextField
                        name={"name"}
                        required
                        error={touched.name && errors.name}
                        helperText={touched.name && errors.name}
                        className={classes.textField}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label={"Name"}
                        disabled={disabled}
                        value={values.name}
                      />
                      <TextField
                        name={"description"}
                        error={touched.description && errors.description}
                        helperText={touched.description && errors.description}
                        className={classes.textField}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label={"Description"}
                        value={values.description}
                        disabled={disabled}
                      />
                      <div style={{ marginTop: 10 }}>
                        <FormLabel classes={{ root: classes.groupLabel }}>
                          Target Quantity
                        </FormLabel>
                        <div
                          style={{
                            width: "60%",
                            display: "flex",
                            justifyContent: "space-between"
                          }}
                        >
                          <Select
                            disabled={disabled}
                            style={{ marginTop: 4, marginBottom: 5 }}
                            value={values.target_quantity}
                            name={"target_quantity"}
                            onChange={handleChange}
                            MenuProps={{
                              disableScrollLock: true
                            }}
                          >
                            <MenuItem value="cartesian">
                              Cartesian co-ordinates with fixed z
                            </MenuItem>
                            <MenuItem value="angular">Angles</MenuItem>
                          </Select>
                        </div>
                      </div>
                      {values.target_quantity === "cartesian" && (
                        <div id="cartesian">
                          <div style={{ marginTop: 10 }}>
                            <div>
                              <FormLabel
                                classes={{ root: classes.groupLabel }}
                                style={{ fontSize: 12 }}
                                disabled={disabled}
                                htmlFor="Y"
                              >
                                Center coordinates
                              </FormLabel>
                              <IconTooltip image={center_coordinates} />
                            </div>
                            <div
                              style={{
                                width: "60%",
                                display: "flex",
                                justifyContent: "space-between"
                              }}
                            >
                              <NumberInput
                                allowNegative
                                name={"centerCoordX"}
                                required
                                error={
                                  touched.centerCoordX && errors.centerCoordX
                                }
                                helperText={
                                  touched.centerCoordX && errors.centerCoordX
                                }
                                className={classes.groupThreeNumberFields}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                label={"dX"}
                                value={values.centerCoordX}
                                disabled={disabled}
                              />
                              <NumberInput
                                allowNegative
                                name={"centerCoordY"}
                                required
                                error={
                                  touched.centerCoordY && errors.centerCoordY
                                }
                                helperText={
                                  touched.centerCoordY && errors.centerCoordY
                                }
                                className={classes.groupThreeNumberFields}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                label={"dY"}
                                value={values.centerCoordY}
                                disabled={disabled}
                              />
                              <NumberInput
                                name={"centerCoordZ"}
                                required
                                error={
                                  touched.centerCoordZ && errors.centerCoordZ
                                }
                                helperText={
                                  touched.centerCoordZ && errors.centerCoordZ
                                }
                                className={classes.groupThreeNumberFields}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                label={"dZ"}
                                value={values.centerCoordZ}
                                disabled={disabled}
                              />
                            </div>
                          </div>
                          {/* <div style={{ marginTop: 10 }}>
                        <FormLabel
                          classes={{ root: classes.groupLabel }}
                          disabled={disabled}
                        >
                          Orientation
                        </FormLabel>
                        <div
                          style={{
                            width: "60%",
                            display: "flex",
                            justifyContent: "space-between"
                          }}
                        >
                          <div className={classes.groupThreeNumberFields}>
                            <div>
                              <FormLabel
                                classes={{ root: classes.groupLabel }}
                                style={{ fontSize: 12 }}
                                disabled={disabled}
                                htmlFor="alpha"
                              >
                                Teta
                              </FormLabel>
                              <IconTooltip image={alpha} />
                            </div>
                            <NumberInput
                              name={"alpha"}
                              required
                              error={touched.alpha && errors.alpha}
                              helperText={touched.alpha && errors.alpha}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.alpha}
                              disabled={disabled}
                            />
                          </div>
                          <div className={classes.groupThreeNumberFields}>
                            <div>
                              <FormLabel
                                classes={{ root: classes.groupLabel }}
                                style={{ fontSize: 12 }}
                                disabled={disabled}
                                htmlFor="alpha"
                              >
                                Phi
                              </FormLabel>
                              <IconTooltip image={beta} />
                            </div>
                            <NumberInput
                              name={"beta"}
                              required
                              error={touched.beta && errors.beta}
                              helperText={touched.beta && errors.beta}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.beta}
                              disabled={disabled}
                            />
                          </div>
                          <div className={classes.groupThreeNumberFields}>
                            <div>
                              <FormLabel
                                classes={{ root: classes.groupLabel }}
                                style={{ fontSize: 12 }}
                                disabled={disabled}
                                htmlFor="alpha"
                              >
                                Psi
                              </FormLabel>
                              <IconTooltip
                                image={gama_1}
                                secondaryImage={gama_2}
                              />
                            </div>
                            <NumberInput
                              name={"gama"}
                              required
                              error={touched.gama && errors.gama}
                              helperText={touched.gama && errors.gama}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.gama}
                              disabled={disabled}
                            />
                          </div>
                        </div>
                      </div> */}
                          <div style={{ marginTop: 10 }}>
                            <FormLabel classes={{ root: classes.groupLabel }}>
                              Wavefront dimensions
                            </FormLabel>
                            <div
                              style={{
                                width: "60%",
                                display: "flex",
                                justifyContent: "space-between"
                              }}
                            >
                              <NumberInput
                                name={"WFWidth"}
                                required
                                error={touched.WFWidth && errors.WFWidth}
                                helperText={touched.WFWidth && errors.WFWidth}
                                className={classes.groupTwoNumberFields}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                label={"Width"}
                                value={values.WFWidth}
                                disabled={disabled}
                              />
                              <NumberInput
                                name={"WFHeight"}
                                required
                                error={touched.WFHeight && errors.WFHeight}
                                helperText={touched.WFHeight && errors.WFHeight}
                                className={classes.groupTwoNumberFields}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                label={"Length"}
                                value={values.WFHeight}
                                disabled={disabled}
                              />
                            </div>
                          </div>
                          <div style={{ marginTop: 10 }}>
                            <FormLabel classes={{ root: classes.groupLabel }}>
                              Unit
                            </FormLabel>
                            <div
                              style={{
                                width: "60%",
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: "10px"
                              }}
                            >
                              <Select
                                disabled={disabled}
                                style={{ marginTop: 4, marginBottom: 5 }}
                                value={values.unit}
                                name={"unit"}
                                onChange={handleChange}
                                MenuProps={{
                                  disableScrollLock: true
                                }}
                              >
                                <MenuItem value="μm">μm</MenuItem>
                                <MenuItem value="mm">mm</MenuItem>
                              </Select>
                            </div>
                          </div>
                        </div>
                      )}
                      {values.target_quantity === "angular" && (
                        <div id="angular">
                          <div style={{ marginTop: 10 }}>
                            <div>
                              <FormLabel
                                classes={{ root: classes.groupLabel }}
                                style={{ fontSize: 12 }}
                                disabled={disabled}
                                htmlFor="Y"
                              >
                                Center angles
                              </FormLabel>
                              <IconTooltip image={center_coordinates} />
                            </div>
                            <div
                              style={{
                                width: "60%",
                                display: "flex",
                                justifyContent: "space-between"
                              }}
                            >
                              <NumberInput
                                allowNegative
                                name={"angular_center_angle_x"}
                                required
                                error={
                                  touched.angular_center_angle_x &&
                                  errors.angular_center_angle_x
                                }
                                helperText={
                                  touched.angular_center_angle_x &&
                                  errors.angular_center_angle_x
                                }
                                className={classes.groupThreeNumberFields}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                label={"X"}
                                value={values.angular_center_angle_x}
                                disabled={disabled}
                              />
                              <NumberInput
                                allowNegative
                                name={"angular_center_angle_y"}
                                required
                                error={
                                  touched.angular_center_angle_y &&
                                  errors.angular_center_angle_y
                                }
                                helperText={
                                  touched.angular_center_angle_y &&
                                  errors.angular_center_angle_y
                                }
                                className={classes.groupThreeNumberFields}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                label={"Y"}
                                value={values.angular_center_angle_y}
                                disabled={disabled}
                              />
                            </div>
                          </div>
                          <div style={{ marginTop: 10 }}>
                            <FormLabel classes={{ root: classes.groupLabel }}>
                              Opening angles
                            </FormLabel>
                            <div
                              style={{
                                width: "60%",
                                display: "flex",
                                justifyContent: "space-between"
                              }}
                            >
                              <NumberInput
                                name={"opening_angle_x"}
                                required
                                error={
                                  touched.opening_angle_x &&
                                  errors.opening_angle_x
                                }
                                helperText={
                                  touched.opening_angle_x &&
                                  errors.opening_angle_x
                                }
                                className={classes.groupTwoNumberFields}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                label={"X"}
                                value={values.opening_angle_x}
                                disabled={disabled}
                              />
                              <NumberInput
                                name={"opening_angle_y"}
                                required
                                error={
                                  touched.opening_angle_y &&
                                  errors.opening_angle_y
                                }
                                helperText={
                                  touched.opening_angle_y &&
                                  errors.opening_angle_y
                                }
                                className={classes.groupTwoNumberFields}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                label={"Y"}
                                value={values.opening_angle_y}
                                disabled={disabled}
                              />
                            </div>
                          </div>
                          <div style={{ marginTop: 10 }}>
                            <FormLabel classes={{ root: classes.groupLabel }}>
                              Unit
                            </FormLabel>
                            <div
                              style={{
                                width: "60%",
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: "10px"
                              }}
                            >
                              <Select
                                disabled={disabled}
                                style={{ marginTop: 4, marginBottom: 5 }}
                                value={values.angular_unit}
                                name={"angular_unit"}
                                onChange={event =>
                                  this.changeAngularUnit(
                                    values,
                                    event.target.value,
                                    setFieldValue
                                  )
                                }
                                MenuProps={{
                                  disableScrollLock: true
                                }}
                              >
                                <MenuItem value="degrees">Degrees</MenuItem>
                                <MenuItem value="radians">Radians</MenuItem>
                              </Select>
                            </div>
                          </div>
                        </div>
                      )}
                      <NumberInput
                        name={"tolerance"}
                        style={{ marginTop: 16, marginRight: 16 }}
                        required
                        error={touched.tolerance && errors.tolerance}
                        helperText={touched.tolerance && errors.tolerance}
                        className={classes.textField}
                        onChange={e => {
                          setFieldValue(
                            "tolerance",
                            String(e.target.value),
                            false
                          );
                        }}
                        noAutoDecimize={true}
                        onBlur={handleBlur}
                        label={"Tolerance"}
                        value={values.tolerance}
                        disabled={disabled}
                      />
                      <GenericTextInput
                        name={"iteration_Stop"}
                        required={true}
                        error={touched.iteration_Stop && errors.iteration_Stop}
                        helperText={
                          touched.iteration_Stop && errors.iteration_Stop
                        }
                        classes={classes.textField}
                        handleUpdateField={handleChange}
                        onBlur={handleBlur}
                        label={"Max iterations"}
                        value={values.iteration_Stop}
                        disabled={disabled}
                        type="number"
                        minValue={1}
                      />
                    </Form>
                  </Grid>
                );
              }}
            </Formik>
            <Grid item xs={rowLayout ? 6 : 12}>
              {ffwfTarget && (
                <Wavefront
                  wavefront={this.props.wavefront || ffwfTarget.wave_front}
                  wfWidth={this.getWavefrontWidth(ffwfTarget)}
                  wfHeight={this.getWavefrontHeight(ffwfTarget)}
                  unit={this.getWavefrontUnit(ffwfTarget)}
                  onChange={this.handleWavefrontFileImport}
                  onExport={this.exportWavefront}
                  isEditing={isEditing || isCreateMode}
                  onGeneratorConfirm={
                    this.props.onSubmit &&
                    ((wavefront, onFinish) =>
                      this.handleWavefrontGeneratorConfirmation(
                        wavefront,
                        onFinish
                      ))
                  }
                  onGenerateAsyncWavefront={this.generateAsyncWavefront}
                  onlyAmplitude
                  sampleScripts={this.props.sampleScripts}
                  scriptId={ffwfTarget.wave_front_design_script}
                  xLabel={this.getWavefrontXLabel(ffwfTarget)}
                  xCenter={
                    ffwfTarget.target_quantity == "angular"
                      ? ffwfTarget.angular_center_angles[0]
                      : null
                  }
                  yCenter={
                    ffwfTarget.target_quantity == "angular"
                      ? ffwfTarget.angular_center_angles[1]
                      : null
                  }
                  yLabel={this.getWavefrontYLabel(ffwfTarget)}
                  showImportFailed={showImportFailed}
                />
              )}
            </Grid>
          </Grid>
        </>
        {this.state.snackbar.visible && (
          <DirectionSnackbar message={this.state.snackbar.message} />
        )}
      </>
    );
  }
}

export default withStyles(styles)(FFWFTargetForm);
